import React from "react";
import "./drawerToggleButton.css";

export default function DrawerToggleButton(props) {
  return (
    <button
      onClick={() => {
        props.toggle();
      }}
      className="toggle-button"
    >
      <div className="toggleButtonLine" />
      <div className="toggleButtonLine" />
      <div className="toggleButtonLine" />
    </button>
  );
}
