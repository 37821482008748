import React from "react";
import { ChromePicker } from "react-color";

export default function ColorPicker(props) {
  return (
    <div className="card popOut">
      <h1 className="display-5">Select {props.source} color</h1>
      <ChromePicker color={props.color} onChangeComplete={props.answer} />
      <button className="btn btn-sm btn-secondary" onClick={props.finalAnswer}>
        Set {props.source} Color
      </button>
    </div>
  );
}
