import React, { Component } from "react";
import LoadingScreen from "../../../common/LoadingScreen";
import NavBar from "../../nav/NavBar";
import { siteName } from "../../../versionInfo";
import Footer from "../../Footer";
import { withFirebase } from "../../../firebase";
import { compose } from "redux";
import PostsTable from "./PostsTable";
import "./posts.css";
import ViewPost from "./ViewPost";

class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      posts: [],
      sortOrder: true,
      selectedPost: undefined,
    };
  }
  getPosts = () => {
    this.props.firebase.db
      .collection("site")
      .doc("news")
      .collection("posts")
      .get()
      .then(
        function (res) {
          let posts = [];
          res.forEach((post, i) => {
            console.log("got post");
            posts.push(post.data());
          });
          this.setState({ posts: posts, loading: false });
        }.bind(this)
      )
      .catch(function (err) {
        console.log("error getting posts: " + err.message);
      });
  };
  componentDidMount() {
    this.getPosts();
  }
  sort = () => {
    let posts = this.state.posts;
    if (this.state.sortOrder) {
      posts.sort((a, b) => parseFloat(a.time) - parseFloat(b.time));
    } else {
      posts.sort((a, b) => parseFloat(b.time) - parseFloat(a.time));
    }
    this.setState({ posts: posts, sortOrder: !this.state.sortOrder });
  };
  selectPost = (post) => {
    console.log("selecting post: " + JSON.stringify(post));
    if (this.state.selectedPost == post) {
      this.setState({ selectedPost: undefined });
    } else {
      this.setState({ selectedPost: post });
    }
  };
  render() {
    let styleObj = {
      backgroundColor: this.props.global.colors.backgroundColor,
    };
    return (
      <div style={styleObj} className="pageContainer">
        {this.state.loading ? (
          <LoadingScreen />
        ) : (
          <div style={styleObj}>
            {" "}
            <NavBar
              nav={this.props.global.nav}
              colors={this.props.global.colors}
              loggedIn={this.props.signedIn}
              admin={this.props.admin}
            />
            <div className="container">
              <div className="row text-center">
                <h1
                  className="display-4 headline"
                  style={{ color: this.props.global.colors.secondaryColor }}
                >
                  {siteName} News
                </h1>
              </div>
              {this.state.selectedPost !== undefined ? (
                <ViewPost
                  firebase={this.props.firebase}
                  post={this.state.selectedPost}
                />
              ) : (
                ""
              )}
              <PostsTable
                posts={this.state.posts}
                selectPost={this.selectPost}
                sort={this.sort}
              />
            </div>
          </div>
        )}
        <Footer />
      </div>
    );
  }
}

export default compose(withFirebase)(News);
