import React, { Component } from "react";
import { withFirebase } from "./../../firebase/index";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { compose } from "redux";
import Toolbar from "./toolbar/Toolbar";
import NavBar from "./../nav/NavBar";
import Pages from "./Pages/Pages";
import Global from "./Global/Global";
import PageManagement from "./Pages/PageManagement";
import Images from "./Images/Images";
import Upload from "./Images/Upload";
import "./admindashboard.css";
import EventsManagement from "./Pages/Events/EventsManagement";
import NewsManagement from "./Pages/News/NewsManagement";
import DashboardHome from "./DashboardHome";

class AdminDashboard extends Component {
  constructor() {
    super();
    this.state = {};
  }
  logOut = () => {
    this.props.firebase.auth
      .signOut()
      .then(function (res) {
        this.props.history.push("/login");
      })
      .catch(function (err) {
        console.log("error logging out: " + err.message);
      });
  };
  componentDidMount() {}
  render() {
    return (
      <div>
        <div className="dashboardContainer">
          <Toolbar logOut={this.logOut} />{" "}
          <Route exact path="/admin-dashboard" component={DashboardHome} />
          <Route exact path="/admin-dashboard/global" component={Global} />
          <Route exact path="/admin-dashboard/pages" component={Pages} />
          <Route
            exact
            path="/admin-dashboard/events"
            component={EventsManagement}
          />
          <Route
            exact
            path="/admin-dashboard/news"
            component={NewsManagement}
          />
          <Route
            path="/admin-dashboard/pages/:page"
            component={PageManagement}
          />
          <Route exact path="/admin-dashboard/images" component={Images} />
          <Route path="/admin-dashboard/images/upload" component={Upload} />
        </div>
      </div>
    );
  }
}

export default compose(withFirebase)(AdminDashboard);
