import React, { Component } from "react";

export default class EditFeature extends Component {
  constructor() {
    super();
    this.state = {
      title: "",
    };
  }
  setFields = () => {
    console.log(
      "inside setFields for editFeature: " + JSON.stringify(this.props.feature)
    );
    this.setState({ title: this.props.feature.title });
  };
  titleChange = (event) => {
    this.setState({ title: event.target.value });
  };
  componentDidMount() {
    this.setFields();
  }
  render() {
    return (
      <div className="editFeature">
        <div className="exit">
          <button className="exit" onClick={this.props.exit}>
            EXIT
          </button>
        </div>
        <label htmlFor="title">Feature Title: </label>
        <input
          type="text"
          className="form-control"
          name="title"
          id="title"
          value={this.state.title}
          onChange={this.titleChange}
        />
        <button
          className="btn btn-secondary btn-sm"
          onClick={() => {
            this.props.submit(this.state.title);
          }}
        >
          Save Feature Title
        </button>
      </div>
    );
  }
}
