import React from "react";
import "./contact.css";
import LoadingScreen from "./../../../common/LoadingScreen";
import logo from "./../../../assets/logo.png";

export default function ContactForm(props) {
  return (
    <div className="containerOfContactContainer">
      {props.loading ? (
        <LoadingScreen />
      ) : (
        <div className="container contactFormContainer container-fluid">
          <div className="form-group row">
            <div className="col-sm-6">
              <label htmlFor="name">
                <p>Name</p>
              </label>

              <input
                type="text"
                className="form-control"
                onChange={props.change}
                value={props.name}
                name="contactName"
                id="name"
              />
            </div>

            <div className="col-sm-6">
              <label htmlFor="name">
                <p>Email</p>
              </label>

              <input
                type="email"
                className="form-control"
                value={props.email}
                onChange={props.change}
                name="contactEmail"
                id="email"
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col col-sm-12">
              {" "}
              <label htmlFor="content">
                <p className="contactMessage">Your Message:</p>
              </label>
              <textarea
                className="form-control"
                name="contactMessage"
                id="content"
                value={props.content}
                onChange={props.change}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col col-sm-12">
              <button
                onClick={props.submit}
                className="btn btn-secondary contactSubmit"
                disabled={props.valid ? false : true}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
