export default function formatDate(date) {
  var d = new Date(date);
  var hh = d.getHours();
  var m = d.getMinutes();
  var s = d.getSeconds();
  let meridian = "AM";
  let month = d.getMonth();
  month = month + 1;
  let day = d.getDay();
  let dayString;
  let dateNumber = d.getDate();
  switch (day) {
    case 0:
      dayString = "Sunday";
      break;
    case 1:
      dayString = "Monday";
      break;
    case 2:
      dayString = "Tuesday";
      break;
    case 3:
      dayString = "Wednesday";
      break;
    case 4:
      dayString = "Thursday";
      break;
    case 5:
      dayString = "Friday";
      break;
    case 6:
      dayString = "Saturday";
      break;
    default:
      break;
  }

  if (hh >= 12) {
    hh = hh - 12;
    meridian = "PM";
  }
  if (hh == 0) {
    hh = 12;
  }
  if (m < 10) {
    m = "0" + m;
  }
  let timeString =
    hh +
    ":" +
    m +
    ":" +
    s +
    meridian +
    " " +
    dayString +
    ", " +
    month +
    "-" +
    dateNumber +
    "-" +
    d.getFullYear();

  return timeString;
}
