import React, { Component } from "react";
import { withFirebase } from "./../../../../firebase/index";
import { compose } from "redux";
import { Link } from "react-router-dom";
import AddImage from "./../../../../common/AddImage";
import Backdrop from "./../../../../common/Backdrop";
import AboutParagraph from "./AboutParagraph";

class AboutManagement extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      imageMessage: undefined,
      addImage: false
    };
  }
  saveAboutImage = () => {
    console.log("saveAboutImage");
    this.setState({ loading: true });
    this.props.firebase.db
      .collection("site")
      .doc("about")
      .set({ aboutImage: this.state.aboutImage }, { merge: true })
      .then(
        function(res) {
          this.setState({
            loading: false,
            imageMessage: "Successfully Saved About Image"
          });
        }.bind(this)
      )
      .catch(
        function(err) {
          console.log("error saving about image : " + err.message);
          this.setState({
            loading: false,
            imageMessage: "Error Saving About Image: " + err.message
          });
        }.bind(this)
      );
  };
  saveAboutParagraph = () => {
    this.setState({ aboutParagraphLoading: true });
    this.props.firebase.db
      .collection("site")
      .doc("about")
      .set({ aboutParagraph: this.state.aboutParagraph }, { merge: true })
      .then(
        function(res) {
          console.log("successfully saved ");
          this.setState({
            aboutParagraphLoading: false,
            aboutParagraphMessage: "Successfully Saved About Paragraph"
          });
        }.bind(this)
      )
      .catch(
        function(err) {
          console.log("error saving about paragraph: " + err.message);
          this.setState({
            aboutParagraphLoading: false,
            aboutParagraphMessage:
              "Error Saving About Paragraph, Check Connection And Try Again"
          });
        }.bind(this)
      );
  };
  onAboutParagraphChange = event => {
    console.log("aboutParagraphChange: " + event.target.value);
    this.setState({ aboutParagraph: event.target.value });
  };
  addAboutImage = () => {
    this.setState({ addImage: true });
  };
  setAboutImage = image => {
    console.log("setting about image");
    this.setState({ addImage: false, aboutImage: image });
  };
  closeAddImage = () => {
    this.setState({ addImage: false });
  };
  getSiteImages = () => {
    console.log('getting site images')
    this.props.firebase.db
      .collection("site-images")
      .get()
      .then(
        function(res) {
          let images = [];
          res.forEach((image, i) => {
            console.log("got image");
            images.push(image.data());
          });

          this.setState({ siteImages: images });
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting site images: " + err.message);
      });
  };
  getAboutStuff = () => {
    console.log("getting about stuff");
    this.props.firebase.db
      .collection("site")
      .doc("about")
      .get()
      .then(
        function(res) {
          if (res.exists) {
            let about = res.data();
            let aboutImage, aboutParagraph;
            if (about.aboutImage !== undefined) {
              aboutImage = about.aboutImage;
            } else {
              aboutImage = undefined;
            }
            if (about.aboutParagraph !== undefined) {
              aboutParagraph = about.aboutParagraph;
            } else {
              aboutParagraph = "";
            }
            this.setState({
              aboutImage: aboutImage,
              aboutParagraph: aboutParagraph,
              loading: false
            });
          } else {
            this.setState({aboutImage:undefined,aboutParagraph:'', loading:false})
          }
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting about stuff: " + err.message);
      });
  };
  componentDidMount() {
    this.getSiteImages();
    this.getAboutStuff();
  }
  render() {
    return (
      <div className="dashboardPanel">
        {this.state.loading ? (
          <i className="spinner fas fa-circle-notch fa-spin" />
        ) : (
          <div>
            <Link className="back" to="/admin-dashboard/pages">
              {"<- Back To Pages"}
            </Link>
            <h1 className="display-5">About Management</h1>
            <div className="dashboardBlock container">
              <p className="display-5">About Image</p>{" "}
              {this.state.aboutImage !== undefined ? (
                <img className="aboutImage" src={this.state.aboutImage.url} />
              ) : (
                ""
              )}
              {this.state.imageMessage !== undefined ? (
                <p className="alert alert-primary">{this.state.imageMessage}</p>
              ) : (
                ""
              )}
              <div className="row">
                <div className="col col-xs-12">
                  {" "}
                  <button
                    onClick={this.addAboutImage}
                    className="btn btn-sm btn-secondary"
                  >
                    Add/Change About Image
                  </button>
                  <div className="save">
                    <button
                      className="btn btn-sm btn-secondary dashboardButton"
                      onClick={this.saveAboutImage}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <AboutParagraph
              save={this.saveAboutParagraph}
              onChange={this.onAboutParagraphChange}
              paragraph={this.state.aboutParagraph}
              message={this.state.aboutParagraphMessage}
              loading={this.state.aboutParagraphLoading}
            />
            {this.state.addImage ? (
              <div>
                <AddImage
                  siteImages={this.state.siteImages}
                  selectImage={this.setAboutImage}
                />
                <Backdrop click={this.closeAddImage} />
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(AboutManagement);
