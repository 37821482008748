import React, { Component } from "react";
import Alert from "../../../../common/Alert";
import LoadingScreen from "../../../../common/LoadingScreen";
import SiteImageContainer from "../../../../common/SiteImageContainer";
import PostImageUploadUtility from "./PostImageUploadUtility";
import Confirmation from "../../../../common/Confirmation";

export default class EditPost extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      postTitle: "",
      postContent: "",
      postPictures: [],
      loadedProps: false,
      selectedImage: undefined,
      confirmation: false,
      valid: true,
    };
  }
  setFields = () => {
    if (!this.state.loadedProps) {
      this.setState(
        {
          postTitle: this.props.post.title,
          postContent: this.props.post.content,
          loadedProps: true,
        },
        () => {
          this.getImages();
        }
      );
    } else {
      this.getImages();
    }
  };
  getImages = () => {
    this.props.firebase.db
      .collection("site")
      .doc("news")
      .collection("posts")
      .doc(this.props.post.id)
      .collection("images")
      .get()
      .then(
        function (res) {
          let pictures = [];
          res.forEach((pic, i) => {
            console.log("got picture");
            pictures.push(pic.data());
          });
          this.setState({ postPictures: pictures, loading: false });
        }.bind(this)
      )
      .catch(function (err) {
        console.log("error getting images: " + err.message);
      });
  };
  componentDidMount() {
    this.setFields();
  }
  selectImage = (image) => {
    let images = [...this.state.postPictures];
    let selectedImage;
    this.state.postPictures.forEach(
      function (siteImage, i) {
        if (image == siteImage) {
          console.log("found image that was clicked");
          if (images[i].selected) {
            images[i].selected = false;
            selectedImage = undefined;
          } else {
            images[i].selected = true;
            selectedImage = images[i];
          }
        } else {
          images[i].selected = false;
        }
      }.bind(this)
    );
    this.setState({ postPictures: images, selectedImage: selectedImage });
  };
  deletePhoto = (photo) => {
    this.deletePhotoFromStorage(photo).then(
      function (res) {
        this.deletePhotoFromDB(photo);
      }.bind(this)
    );
  };
  deletePhotoFromStorage = (pic) => {
    console.log("inside deletePhotoFromStorage");
    return new Promise(
      function (res, rej) {
        this.props.firebase.storage
          .ref(pic.path)
          .delete()
          .then(
            function (obj) {
              console.log("successfully deleted image");
              this.setState({
                message: "Successfully Deleted Photo From Storage",
                messageType: "success",
              });
              res("success");
            }.bind(this)
          )
          .catch(
            function (err) {
              console.log("error deleting image: " + err.message);
              this.setState({
                message: "Error Deleting Photo From Storage",
                messageType: "danger",
              });
              res(err.message);
            }.bind(this)
          );
      }.bind(this)
    );
  };
  deletePhotoFromDB = (pic) => {
    this.props.firebase.db
      .collection("site")
      .doc("news")
      .collection("posts")
      .doc(this.props.post.id)
      .collection("images")
      .doc(pic.id)
      .delete()
      .then(
        function (res) {
          console.log("successfully deleted photo from db");
          this.setState(
            {
              message: "Successfully Deleted Photo From DB",
              messageType: "success",
            },
            () => {
              this.getImages();
            }
          );
        }.bind(this)
      )
      .catch(
        function (err) {
          console.log("error deleting photo from db");
          this.setState(
            { message: "Error Deleting Photo From DB", messageType: "danger" },
            () => {
              this.getImages();
            }
          );
        }.bind(this)
      );
  };
  deletePhotoConfirmation = () => {
    this.setState({
      confirmation: true,
      confirmationQuestion:
        "Are You Sure You Want To Delete This Photo? This Cannot Be Undone.",
    });
  };
  answerConfirmDelete = (answer) => {
    if (answer) {
      this.setState({ loading: true });
      this.deletePhoto(this.state.selectedImage);
    } else {
      this.setState({ confirmation: false, confirmationQuestion: undefined });
    }
  };
  closeMessage = () => {
    this.setState({ message: undefined, messageType: "danger" });
  };
  finishUploadImages = () => {
    this.setState(
      {
        valid: true,
        message: "Successfully Uploaded Images, You Can Mess With Stuff Again.",
        messageType: "success",
      },
      () => {
        this.getImages();
      }
    );
  };
  startUpload = () => {
    this.setState({
      valid: false,
      message: "Uploading Images, Do Not Mess With Stuff During This Process.",
      messageType: "danger",
    });
  };
  onChange = (event) => {
    console.log("inside onchange");

    this.setState({ [event.target.name]: event.target.value });
  };
  savePost = () => {
    let post = {
      id: this.props.post.id,
      title: this.state.postTitle,
      content: this.state.postContent,
      time: this.props.post.time,
    };
    this.props.firebase.db
      .collection("site")
      .doc("news")
      .collection("posts")
      .doc(this.props.post.id)
      .set(post)
      .then(
        function (res) {
          console.log("successfully saved post");
          this.props.finish();
        }.bind(this)
      )
      .catch(
        function (err) {
          console.log("error setting post to database: " + err.message);
          this.setState({
            message:
              "There Was An Error Setting The Post To The Database. Copy Your Information, Refresh, Try Again.",
            messageType: "danger",
          });
        }.bind(this)
      );
  };
  render() {
    let message, confirmation;
    if (this.state.confirmation) {
      confirmation = (
        <Confirmation
          question={this.state.confirmationQuestion}
          answer={this.answerConfirmDelete}
        />
      );
    } else {
      confirmation = "";
    }
    if (this.state.message !== undefined) {
      let messageType;
      if (
        this.state.messageType == undefined ||
        this.state.messageType == null
      ) {
        messageType = "danger";
      } else {
        messageType = this.state.messageType;
      }
      message = (
        <Alert
          closeMessage={this.closeMessage}
          alertType={messageType}
          message={this.state.message}
        />
      );
    } else {
      message = "";
    }
    return (
      <div>
        {this.state.loading ? (
          <LoadingScreen />
        ) : (
          <div>
            <h1 className="display-4">Edit Post</h1>
            <div className="container">
              {message}
              <div className="row">
                <div className="col col-xs-12">
                  <p className="lead">Post Title:</p>
                </div>
              </div>
              <div className="row">
                <div className="col col-xs-12">
                  <input
                    type="text"
                    value={this.state.postTitle}
                    onChange={this.onChange}
                    name="postTitle"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col col-xs-12">
                  <p className="lead">Post Content:</p>
                </div>
              </div>
              <div className="row">
                <div className="col col-xs-12">
                  <textarea
                    type="text"
                    value={this.state.postContent}
                    onChange={this.onChange}
                    name="postContent"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row">
                {this.state.selectedImage !== undefined ? (
                  <div>
                    <button
                      className="btn btn-sm btn-secondary"
                      onClick={this.deletePhotoConfirmation}
                    >
                      Delete Selected Photo
                    </button>
                  </div>
                ) : (
                  ""
                )}
                <div className="col col-xs-12">
                  <SiteImageContainer
                    siteImages={this.state.postPictures}
                    select={this.selectImage}
                    selectedImage={this.state.selectedImage}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col col-xs-12">
                  <div className="border imageBorder">
                    <p className="lead">Post Images</p>
                    <PostImageUploadUtility
                      postID={this.props.post.id}
                      finish={this.finishUploadImages}
                      startUpload={this.startUpload}
                    />
                  </div>
                </div>
              </div>
              <button
                className="btn btn-sm btn-secondary dashboardButton"
                onClick={this.savePost}
                disabled={this.state.valid ? false : true}
              >
                Save Event
              </button>
              {message}
              {confirmation}
            </div>
          </div>
        )}
      </div>
    );
  }
}
