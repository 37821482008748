import React from "react";
import { Link } from "react-router-dom";
import { siteName } from "../../../versionInfo";

export default function RelativeLogoLeft(props) {
  let backgroundColor;
  if (props.backgroundColor == "light") {
    console.log("light");
    backgroundColor = "#ffffff";
  } else if (props.backgroundColor == "dark") {
    console.log("dark");
    backgroundColor = "#000000";
  } else if (props.backgroundColor == "inherit") {
    backgroundColor = props.colors.backgroundColor;
  }
  return (
    <nav
      className={
        props.textColor == "light"
          ? "sticky navbar navbar-expand-lg navbar-dark"
          : "sticky navbar navbar-expand-lg navbar-light"
      }
      style={{ backgroundColor: backgroundColor }}
    >
      <Link className="navbar-brand d-flex w-50 mr-auto nav-link " to="/">
        <img src={props.logo} className="navBarLogo navBarLogoLeft" />{" "}
        <h3
          className={
            props.textColor == "light"
              ? "display-5 titleText lightText"
              : "display-5 titleText darkText"
          }
        >
          {siteName}
        </h3>
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className="collapse navbar-collapse flex-grow-1 text-md-right"
        id="navbarNav"
      >
        <ul className="navbar-nav ml-auto">
          <li className="nav-item active">
            <Link
              className={
                props.textColor == "light"
                  ? "nav-link lightText"
                  : "nav-link darkText"
              }
              to="/"
            >
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={
                props.textColor == "light"
                  ? "nav-link lightText"
                  : "nav-link darkText"
              }
              to="/about"
            >
              About
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/events"
              className={
                props.textColor == "light"
                  ? "nav-link lightText"
                  : "nav-link darkText"
              }
              href="/events"
            >
              Events
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/news"
              className={
                props.textColor == "light"
                  ? "nav-link lightText"
                  : "nav-link darkText"
              }
              to="/news"
            >
              News
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/contact"
              className={
                props.textColor == "light"
                  ? "nav-link lightText"
                  : "nav-link darkText"
              }
              to="/contact"
            >
              Contact
            </Link>
          </li>

          <li className="nav-item dropdown">
            <a
              className={
                props.textColor == "light"
                  ? "nav-link dropdown-toggle lightText"
                  : "nav-link dropdown-toggle darkText"
              }
              href="#"
              id="navbarDropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              More
            </a>
            <div
              className="dropdown-menu subMenu"
              aria-labelledby="navbarDropdownMenuLink"
            >
              {props.pages !==undefined && props.pages.members.enabled  ? (
                <Link className="dropdown-item" to="/members">
                  Members
                </Link>
              ) : (
                ""
              )}
              {props.pages!== undefined &&props.pages.resources.enabled ? (
                <Link className="dropdown-item" to="/resources">
                  Resources
                </Link>
              ) : (
                ""
              )}
              {props.pages!== undefined &&props.pages.swarms.enabled ? (
                <Link className="dropdown-item" to="/swarms">
                  Swarms
                </Link>
              ) : (
                ""
              )}
              {props.pages!== undefined &&props.pages.marketplace.enabled ? (
                <Link className="dropdown-item" to="/marketplace">
                  Marketplace
                </Link>
              ) : (
                ""
              )}
              {props.pages!== undefined &&props.pages.gallery.enabled ? (
                <Link className="dropdown-item" to="/gallery">
                  Gallery
                </Link>
              ) : (
                ""
              )}
              {props.loggedIn ? (
                <div>
                  {props.admin ? (
                    <div>
                      <Link className="dropdown-item" to="/dashboard">
                        Dashboard
                      </Link>
                      <Link className="dropdown-item" to="/admin-dashboard">
                        Site Admin
                      </Link>
                    </div>
                  ) : (
                    <Link className="dropdown-item" to="/dashboard">
                      Dashboard
                    </Link>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </li>
          <li className="nav-item">
            {props.loggedIn ? (
              <a
                className={
                  props.textColor == "light"
                    ? "nav-link lightText"
                    : "nav-link darkText"
                }
                onClick={props.logOut}
              >
                Log Out
              </a>
            ) : (
              <Link
                className={
                  props.textColor == "light"
                    ? "nav-link lightText"
                    : "nav-link darkText"
                }
                to="/login"
              >
                Login
              </Link>
            )}
          </li>
        </ul>
      </div>
    </nav>
  );
}
