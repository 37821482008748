import React, { Component } from "react";
import ContactForm from "./ContactForm";
import axios from "axios";
import LoadingScreen from "../../../common/LoadingScreen";
import NavBar from "../../nav/NavBar";
import { siteName, sendMail, contactEmail } from "../../../versionInfo";
import Footer from "../../Footer";
import Alert from "./../../../common/Alert";

export default class Contact extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      contactName: "",
      contactEmail: "",
      contactMessage: "",
    };
  }
  checkContact = () => {
    console.log("inside checkContact");
    if (
      this.state.contactName !== "" &&
      this.state.contactEmail !== "" &&
      this.state.contactMessage !== ""
    ) {
      this.setState({ contactValid: true });
    } else {
      this.setState({ contactValid: false });
    }
  };
  contactChange = (event) => {
    console.log("inside contactChange: " + event.target.value);
    this.setState(
      { [event.target.name]: event.target.value, contactValid: false },
      () => {
        this.checkContact();
      }
    );
  };
  contactSubmit = async () => {
    console.log("inside submitForm");
    this.setState({ contactLoading: true });
    let message = {
      name: this.state.contactName,
      email: this.state.contactEmail,

      message: this.state.contactMessage,
      to: { contactEmail },
    };

    console.log("message is : " + JSON.stringify(message));
    await axios
      .post(
        { sendMail },
        {
          message,
        }
      )
      .then(
        function (res) {
          console.log("res from submitForm is : " + JSON.stringify(res));
          this.setState({
            contactStatusMessage:
              "Thanks for filling out the form. I'll be in contact as soon as possible.",
            contactName: "",
            contactPhone: "",
            contactEmail: "",
            contactMessage: "",
            messageType: "success",
            contactLoading: false,
          });
        }.bind(this)
      )
      .catch(
        function (err) {
          console.log("error sending message: " + err.message);
          this.setState({
            contactStatusMessage:
              "There Was An Error Accessing The Server. Please Try To Send Your Message Again, Or Email Brett@BrettKozmaDesigns.com",
            messageType: "danger",
          });
        }.bind(this)
      );
  };
  closeMessage = () => {
    this.setState({ contactStatusMessage: undefined, messageType: "danger" });
  };
  render() {
    let styleObj = {
      backgroundColor: this.props.global.colors.backgroundColor,
      height: "100%",
      minHeight: "100%",
    };
    let contactStatusMessage;
    if (this.state.contactStatusMessage !== undefined) {
      let messageType;
      if (
        this.state.messageType == undefined ||
        this.state.messageType == null
      ) {
        messageType = "danger";
      } else {
        messageType = this.state.messageType;
      }
      contactStatusMessage = (
        <Alert
          closeMessage={this.closeMessage}
          alertType={messageType}
          message={this.state.contactStatusMessage}
        />
      );
    } else {
      contactStatusMessage = "";
    }
    return (
      <div style={styleObj} className="pageContainer">
        {this.state.loading ? (
          <LoadingScreen />
        ) : (
          <div style={styleObj}>
            {" "}
            <NavBar
              nav={this.props.global.nav}
              colors={this.props.global.colors}
              loggedIn={this.props.signedIn}
              admin={this.props.admin}
            />
            <div className="container">
              <div className="row text-center">
                <h1
                  className="display-4 headline"
                  style={{ color: this.props.global.colors.secondaryColor }}
                >
                  Contact {siteName}
                </h1>
                {contactStatusMessage}
                <ContactForm
                  name={this.state.contactName}
                  email={this.state.contactEmail}
                  message={this.state.contactMessage}
                  valid={this.state.contactValid}
                  change={this.contactChange}
                  submit={this.contactSubmit}
                  loading={this.state.contactLoading}
                />
                {contactStatusMessage}
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    );
  }
}
