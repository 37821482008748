import React, { Component } from "react";
import NavBar from "./../nav/NavBar";
import LoadingScreen from "./../../common/LoadingScreen";
import { withFirebase } from "./../../firebase/index";
import { compose } from "redux";
import Slider from "./Slider";
import Footer from "./../Footer";
import Features from "./Features";
import "./features.css";

class Landing extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      slider: [{ url: "" }, { url: "" }, { url: "" }],
      homeParagraph: "",
    };
  }
  getFeatures = () => {
    this.props.firebase.db
      .collection("site")
      .doc("home")
      .collection("features")
      .get()
      .then(
        function (res) {
          let features = [];
          res.forEach((feature, i) => {
            console.log("got feature");
            features.push(feature.data());
          });
          this.setState({ loading: false, features: features });
        }.bind(this)
      )
      .catch(
        function (err) {
          console.log("error getting features: " + err.message);
          this.setState({ features: [], loading: false });
        }.bind(this)
      );
  };
  getLandingInfo = () => {
    console.log("getting landing info");
    this.props.firebase.db
      .collection("site")
      .doc("home")
      .get()
      .then(
        function (res) {
          if (res.exists) {
            let home = res.data();
            let landingImage1 = new Image();
            let landingImage2 = new Image();
            let landingImage3 = new Image();

            if (home.slider.image1 !== undefined) {
              console.log("first landing image isnt undefined");
              landingImage1.src = home.slider.image1.url;
            } else {
              landingImage1.src = "";
            }
            if (home.slider.image2 !== undefined) {
              landingImage2.src = home.slider.image2.url;
            } else {
              landingImage2.src = "";
            }
            if (home.slider.image3 !== undefined) {
              landingImage3.src = home.slider.image3.url;
            } else {
              landingImage3 = "";
            }

            landingImage1.onload = () => {
              this.setState(
                {
                  slider: [landingImage1, landingImage2, landingImage3],
                  paragraph: home.homeParagraph,
                },
                () => {
                  this.getFeatures();
                }
              );
            };
          } else {
            console.log("no res for home data");
            this.setState(
              {
                slider: ["", "", ""],
                paragraph: "",
              },
              () => {
                this.getFeatures();
              }
            );
          }
        }.bind(this)
      )
      .catch(
        function (err) {
          console.log("error getting landing content: " + err.message);
          this.setState(
            {
              message: "Error Getting Home Content",
            },
            () => {
              this.getFeatures();
            }
          );
        }.bind(this)
      );
  };
  componentDidMount() {
    this.getLandingInfo();
  }
  render() {
    return (
      <div
        style={{
          backgroundColor: this.props.global.colors.backgroundColor,
        }}
        className="pageContainer"
      >
        {this.state.loading ? (
          <LoadingScreen />
        ) : (
          <div
            style={{
              backgroundColor: this.props.global.colors.backgroundColor,
            }}
          >
            {" "}
            <NavBar
              nav={this.props.global.nav}
              colors={this.props.global.colors}
              loggedIn={this.props.signedIn}
              admin={this.props.admin}
            />
            {this.state.slider[0].url !== "" ? (
              <Slider slider={this.state.slider} />
            ) : (
              ""
            )}
            <div
              className="container container-fluid landingContainer"
              style={{
                backgroundColor: this.props.global.colors.backgroundColor,
              }}
            >
              <div className="landingText">
                <p className="display-4">
                  Welcome To The Fremont Area Beekeepers
                </p>
                <p className="lead">{this.state.paragraph}</p>
              </div>
              <Features features={this.state.features} />
            </div>
          </div>
        )}
        <Footer />
      </div>
    );
  }
}

export default compose(withFirebase)(Landing);
