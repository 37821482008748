import React, { Component } from "react";
import formatDate from "../../common/formatDate";
import LoadingScreen from "../../common/LoadingScreen";

export default class FeaturePostView extends Component {
  constructor() {
    super();
    this.state = { loading: true, images: [], content: "", title: "" };
  }
  getImages = () => {
    this.props.firebase.db
      .collection("site")
      .doc("news")
      .collection("posts")
      .doc(this.props.post.id)
      .collection("images")
      .get()
      .then(
        function (res) {
          let images = [];
          res.forEach((image, i) => {
            images.push(image.data());
          });
          this.setState({ images: images, loading: false });
        }.bind(this)
      )
      .catch(function (err) {
        console.log("error getting imgaes: " + err.message);
      });
  };
  componentDidMount() {
    this.getImages();
  }
  render() {
    return (
      <div>
        {this.state.loading ? (
          <i className="spinner fas fa-circle-notch fa-spin" />
        ) : (
          <div className="viewPost">
            News Post: {formatDate(this.props.post.time)}
            <br />
            <h1 className="display-4">{this.props.post.title}</h1>
            <p className="lead">{this.props.post.content}</p>
            {this.state.images.map((img, i) => (
              <img src={img.url} className="postPicture" />
            ))}
          </div>
        )}
      </div>
    );
  }
}
