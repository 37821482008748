import React, { Component } from "react";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";
import { Link } from "react-router-dom";
import SiteImageContainer from "./../../../common/SiteImageContainer";
import Confirmation from "./../../../common/Confirmation";
import Backdrop from "./../../../common/Backdrop";
import Tabs from "./Tabs";

class Images extends Component {
  constructor() {
    super();

    this.state = {
      siteImages: [],
      selectedImage: undefined,
      confirmDelete: false,
      message: undefined,
    };
  }
  askDelete = () => {
    this.setState({ confirmDelete: true });
  };
  answerDelete = (answer) => {
    this.setState({ confirmDelete: false, loading: true });
    if (answer) {
      let strings = this.state.selectedImage.path.split("/");
      let lastPart = this.state.selectedImage.path.split("/").pop();
      let twoLastPart = "thumb@200_" + lastPart;
      let fiveLastPart = "thumb@500_" + lastPart;
      let twoPath = strings[0] + "/" + strings[1] + "/" + twoLastPart;
      console.log("twoPath is: " + twoPath);
      let fivePath = strings[0] + "/" + strings[1] + "/" + fiveLastPart;
      this.deleteImage(fivePath)
        .then(
          function (res) {
            this.deleteImage(this.state.selectedImage.path)
              .then(
                function (res) {
                  this.props.firebase.db
                    .collection("site-images")
                    .doc(this.state.selectedImage.id)
                    .delete()
                    .then(
                      function (res) {
                        console.log("success");
                        this.setState({
                          loading: false,
                          message: "Successfully Deleted Image",
                          selectedImage: undefined,
                        });
                        this.getSiteImages();
                      }.bind(this)
                    )
                    .catch(function (err) {
                      console.log(
                        "error deleting image from database: " + err.message
                      );
                    });
                }.bind(this)
              )
              .catch(function (err) {
                console.log("error deleting main image: " + err);
              });
          }.bind(this)
        )
        .catch(function (err) {
          console.log("error: " + err);
        });
    } else {
      this.setState({ loading: false });
    }
  };
  deleteImage = (path) => {
    console.log("inside delete image");
    return new Promise(
      function (res, rej) {
        this.props.firebase.storage
          .ref(path)
          .delete()
          .then(function (obj) {
            console.log("successfully deleted image");
            res("success");
          })
          .catch(function (err) {
            console.log("error deleting image: " + err.message);
            res(err.message);
          });
      }.bind(this)
    );
  };
  selectImage = (image) => {
    let images = [...this.state.siteImages];
    let selectedImage;
    this.state.siteImages.forEach(
      function (siteImage, i) {
        if (image == siteImage) {
          console.log("found image that was clicked");
          if (images[i].selected) {
            images[i].selected = false;
            selectedImage = undefined;
          } else {
            images[i].selected = true;
            selectedImage = images[i];
          }
        } else {
          images[i].selected = false;
        }
      }.bind(this)
    );
    this.setState({ siteImages: images, selectedImage: selectedImage });
  };
  getSiteImages = () => {
    this.props.firebase.db
      .collection("site-images")
      .get()
      .then(
        function (res) {
          let images = [];
          res.forEach((image, i) => {
            images.push(image.data());
          });
          this.setState({ siteImages: images });
        }.bind(this)
      )
      .catch(function (err) {
        console.log("error getting site images");
      });
  };
  componentDidMount() {
    console.log("inside images component");
    this.getSiteImages();
  }

  generateFiveThumb = (fivePath) => {
    console.log("inside generate fiveThumb: fivePath is: " + fivePath);
    return new Promise(
      function (promiseRes, rej) {
        this.props.firebase.storage
          .ref(fivePath)
          .getDownloadURL()
          .then(
            function (url) {
              let imageObj = this.state.selectedImage;
              imageObj.thumbTwo = url;
              this.props.firebase.db
                .collection("site-images")
                .doc(this.state.selectedImage.id)
                .update({ thumbFive: url })
                .then(function (res) {
                  console.log("successfully wrote fiveThumb to database");
                  promiseRes(url);
                })
                .catch(function (err) {
                  console.log("error writing five thumb to database");
                  rej(err);
                });
            }.bind(this)
          )
          .catch(
            function (err) {
              console.log(
                "error getting download url for 500 thumb: " + err.messages
              );
              this.setState({
                error: true,
                loading: false,
                message: "Error Generating 500 pixel wide Thumb",
              });
              rej(err);
            }.bind(this)
          );
      }.bind(this)
    );
  };

  generateThumbnails = () => {
    console.log(
      "inside generate thumbnails, path is : " + this.state.selectedImage.path
    );

    let strings = this.state.selectedImage.path.split("/");
    let lastPart = this.state.selectedImage.path.split("/").pop();
    let twoLastPart = "thumb@200_" + lastPart;
    let fiveLastPart = "thumb@500_" + lastPart;
    let twoPath = strings[0] + "/" + strings[1] + "/" + twoLastPart;
    console.log("twoPath is: " + twoPath);
    let fivePath = strings[0] + "/" + strings[1] + "/" + fiveLastPart;

    this.generateFiveThumb(fivePath)
      .then(
        function (res) {
          this.setState(
            { message: "thumbs generated successfully", loading: false },
            () => {
              console.log(
                "success generating thumbnails, check to see if it refreshed"
              );
              this.getSiteImages();
            }
          );
        }.bind(this)
      )
      .catch(function (err) {
        console.log("error generating fiveThumb");
      });
  };

  render() {
    return (
      <div>
        {this.state.loading ? (
          <i className="spinner fas fa-circle-notch fa-spin" />
        ) : (
          <div className="dashboardPanel">
            <h1 className="display-5">Images</h1>

            {this.state.selectedImage !== undefined ? (
              <div>
                <button
                  onClick={this.generateThumbnails}
                  className="dashboardButton btn btn-sm btn-secondary"
                  disabled={
                    this.state.selectedImage.thumbFive !== undefined
                      ? true
                      : false
                  }
                >
                  Generate Thumbnails
                </button>
                <button
                  className="dashboardButton btn btn-sm btn-secondary"
                  onClick={this.askDelete}
                >
                  Delete Image
                </button>
              </div>
            ) : (
              ""
            )}
            {this.state.message !== undefined ? (
              <p className="alert">{this.state.message}</p>
            ) : (
              ""
            )}
            <Tabs active="library" />
            <div className="dashboardBlock">
              Site Images{" "}
              <SiteImageContainer
                addImage={this.addImage}
                siteImages={this.state.siteImages}
                select={this.selectImage}
              />
            </div>
            {this.state.confirmDelete ? (
              <div>
                <Confirmation
                  question="Are You Sure You Want To Delete The Selected Image?"
                  answer={this.answerDelete}
                />
                <Backdrop click={this.closeConfirmDelte} />
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(Images);
