import React from "react";
import formatDate from "../../common/formatDate";
import moment from "moment";

export default function EventView(props) {
  let startDateString, endDateString;
  startDateString = formatDate(props.event.start.datetime);
  endDateString = formatDate(props.event.end.datetime);
  console.log("inside eventView: " + JSON.stringify(props.event));
  return (
    <div className="featureEventView text-center">
      <h1 className="display-4">{props.event.title}</h1>
      <br />
      <div className="container">
        <p className="lead">{props.event.name}</p>
        <div className="row">
          <div className="col col-md-12 col-lg-6 col-xs-12 col-sm-12">
            <p className="lead eventViewItem">
              <b>Start:</b> {startDateString}
            </p>
          </div>
          <div className="col col-md-12 col-lg-6 col-xs-12 col-sm-12">
            <p className="lead eventViewItem">
              <b>End:</b> {endDateString}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col col-md-12 col-lg-6 col-xs-12 col-sm-12">
            <p className="lead eventViewItem">
              <b>Description:</b>
              {props.event.description}
            </p>
          </div>
          <div className="col col-md-12 col-lg-6 col-xs-12 col-sm-12">
            <p className="lead eventViewItem">
              <b>Location:</b> {props.event.location.address} <b>City:</b>
              {props.event.location.city}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
