import React, { Component } from "react";
import AddFeatureEvent from "./AddFeatureEvent";
import AddFeatureGallery from "./AddFeatureGallery";
import AddFeaturePost from "./AddFeaturePost";
import Alert from "../../../../../common/Alert";

export default class AddFeature extends Component {
  constructor() {
    super();
    this.state = {
      addPost: false,
      addEvent: false,
      addGallery: false,
      message: undefined,
      messageType: "danger",
    };
  }
  addPost = () => {
    this.setState({ addPost: true });
  };
  addEvent = () => {
    this.setState({ addEvent: true });
  };
  addGallery = () => {
    this.submit({ type: "gallery" });
  };
  addContactForm = () => {
    this.submit({ type: "contactForm" });
  };
  submit = (feature) => {
    let title;
    let date = new Date();
    let time = date.getTime();
    let id = "" + time;
    switch (feature.type) {
      case "event":
        if (feature.content == "latest") {
          console.log("setting title to SOONEST");
          title = "Soonest";
        } else {
          console.log(
            "setting title to name: " +
              JSON.stringify(feature.content.description)
          );
          title = feature.content.name;
        }
        feature.title = title;
        console.log("submitting event feature");
        feature.id = id;
        this.props.firebase.db
          .collection("site")
          .doc("home")
          .collection("features")
          .doc(id)
          .set(feature)
          .then(
            function (res) {
              this.props.finish("success");
            }.bind(this)
          )
          .catch(
            function (err) {
              console.log("error setting features to db: " + err.message);
              this.props.finish("error");
            }.bind(this)
          );
        break;
      case "post":
        console.log("submitting post feature");

        if (feature.content == "latest") {
          console.log("setting title to Latest");
          title = "Latest";
        } else {
          console.log(
            "setting title to title: " +
              JSON.stringify(feature.content.description)
          );
          title = feature.content.title;
        }
        feature.title = title;
        console.log("submitting post feature");
        feature.id = id;
        this.props.firebase.db
          .collection("site")
          .doc("home")
          .collection("features")
          .doc(id)
          .set(feature)
          .then(
            function (res) {
              this.props.finish("success");
            }.bind(this)
          )
          .catch(
            function (err) {
              console.log("error setting features to db: " + err.message);
              this.props.finish("error");
            }.bind(this)
          );
        break;
      case "gallery":
        console.log("submitting gallery feature");
        feature.title = "gallery";
        console.log("submitting post feature");
        feature.id = id;
        this.props.firebase.db
          .collection("site")
          .doc("home")
          .collection("features")
          .doc(id)
          .set(feature)
          .then(
            function (res) {
              this.props.finish("success");
            }.bind(this)
          )
          .catch(
            function (err) {
              console.log("error setting features to db: " + err.message);
              this.props.finish("error");
            }.bind(this)
          );
        break;
      case "contactForm":
        console.log("submitting contact feature");
        feature.title = "contact";
        console.log("submitting contact feature");
        feature.id = id;
        this.props.firebase.db
          .collection("site")
          .doc("home")
          .collection("features")
          .doc(id)
          .set(feature)
          .then(
            function (res) {
              this.props.finish("success");
            }.bind(this)
          )
          .catch(
            function (err) {
              console.log("error setting features to db: " + err.message);
              this.props.finish("error");
            }.bind(this)
          );
        break;
      default:
        break;
    }
  };
  render() {
    let message;
    if (this.state.message !== undefined) {
      let messageType;
      if (
        this.state.messageType == undefined ||
        this.state.messageType == null
      ) {
        messageType = "danger";
      } else {
        messageType = this.state.messageType;
      }
      message = (
        <Alert
          closeMessage={this.closeEventsMessage}
          alertType={messageType}
          message={this.state.message}
        />
      );
    } else {
      message = "";
    }
    let content = (
      <div>
        <button className="btn btn-sm btn-secondary" onClick={this.addPost}>
          Add News Post
        </button>
        <button className="btn btn-sm btn-secondary" onClick={this.addEvent}>
          Add News Event
        </button>
        <button className="btn btn-sm btn-secondary" onClick={this.addGallery}>
          Add Gallery
        </button>
        <button
          className="btn btn-sm btn-secondary"
          onClick={this.addContactForm}
        >
          Add Contact Form
        </button>
      </div>
    );

    if (this.state.addPost) {
      content = (
        <AddFeaturePost submit={this.submit} firebase={this.props.firebase} />
      );
    }
    if (this.state.addEvent) {
      content = (
        <AddFeatureEvent submit={this.submit} firebase={this.props.firebase} />
      );
    }
    if (this.state.addGallery) {
      content = (
        <AddFeatureGallery
          submit={this.submit}
          firebase={this.props.firebase}
        />
      );
    }
    return (
      <div className="addFeature">
        <div className="exit">
          <button className="exit" onClick={this.props.exit}>
            EXIT
          </button>
        </div>
        <h1>Add Feature</h1>
        {content}
      </div>
    );
  }
}
