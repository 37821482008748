import React from "react";
import FeatureEvent from "./FeatureEvent";
import FeaturePost from "./FeaturePost";
import FeatureContact from "./FeatureContact";
import FeatureGallery from "./FeatureGallery";

export default function Feature(props) {
  let content;
  switch (props.feature.type) {
    case "event":
      content = <FeatureEvent feature={props.feature} />;
      break;
    case "post":
      content = <FeaturePost feature={props.feature} />;
      break;
    case "gallery":
      content = <FeatureGallery feature={props.feature} />;
      break;
    case "contactForm":
      content = <FeatureContact feature={props.feature} />;
      break;
    default:
      break;
  }
  return (
    <div className="feature">
      <h1 className="display-5">{props.feature.title}</h1>
      {content}
    </div>
  );
}
