import React, { Component } from "react";
import { withFirebase } from "./../../firebase/index";
import { compose } from "redux";
import { siteName } from "./../../versionInfo";

class NewUser extends Component {
  render() {
    return <div>Welcome To {siteName}</div>;
  }
}

export default compose(withFirebase)(NewUser);
