import React, { Component } from "react";

import logo from "./../../assets/logo.png";
import { Link } from "react-router-dom";

export default class RegisterForm extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password1: "",
      password2: "",
      firstName: "",
      lastName: "",
      nameValid: false,
      passwordValid: false,
      emailValid: false,
      loading: false
    };
  }
  finished = status => {
    this.setState({ loading: false, message: "Successfully Registered User" });
    this.props.finished(status);
  };
  registerUser = e => {
    e.preventDefault();
    this.setState({ loading: true });
    console.log("inside register");
    this.props.fire.auth
      .createUserWithEmailAndPassword(this.state.username, this.state.password1)
      .then(
        function(user) {
          console.log(
            "success registering user : " + JSON.stringify(user.user)
          );
          let firstName, lastName;
          this.props.fire.db
            .collection("users")
            .doc(user.user.uid)
            .set({
              firstName: this.state.firstName,
              lastName: this.state.lastName,
              username: this.state.username,
              uid: user.user.uid
            })
            .then(
              function(res) {
                console.log("after adding user to db");
                this.finished("success");
              }.bind(this)
            )
            .catch(
              function(err) {
                console.log("error adding user to db: " + err.message);
                this.finished("error");
              }.bind(this)
            );
        }.bind(this)
      )
      .catch(
        function(error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log(
            "error registering user : " + errorCode + " : " + errorMessage
          );
          this.setState({
            message: "Error Registering User: " + errorMessage,
            loading: false
          });
          // ...
        }.bind(this)
      );
  };
  validatePassword = password => {
    if (password.length >= 6) {
      return true;
    } else {
      return false;
    }
  };
  validateEmail = email => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  onChange = event => {
    switch (event.target.name) {
      case "firstName":
        this.setState({ firstName: event.target.value }, () => {
          if (this.state.firstName !== "" && this.state.lastName !== "") {
            this.setState({ nameValid: true });
          } else {
            this.setState({ nameValid: false });
          }
        });
        break;
      case "lastName":
        this.setState({ lastName: event.target.value }, () => {
          if (this.state.firstName !== "" && this.state.lastName !== "") {
            this.setState({ nameValid: true });
          } else {
            this.setState({ nameValid: false });
          }
        });
        break;
      case "username":
        if (this.validateEmail(event.target.value)) {
          this.setState({
            username: event.target.value,
            emailValid: true,
            emailMessage: undefined
          });
        } else {
          this.setState({
            username: event.target.value,
            emailValid: false,
            emailMessage: "Invalid Email Address"
          });
        }

        break;
      case "password1":
        if (this.validatePassword(event.target.value)) {
          this.setState({ password1: event.target.value }, () => {
            if (this.state.password1 === this.state.password2) {
              this.setState({
                passwordValid: true,
                passwordMessage: undefined
              });
            } else {
              this.setState({
                passwordValid: false,
                passwordMessage:
                  "Password must be more than 6 characters, and both versions must match"
              });
            }
          });
        } else {
          this.setState({ password1: event.target.value });
        }
        break;
      case "password2":
        if (this.validatePassword(event.target.value)) {
          this.setState({ password2: event.target.value }, () => {
            if (this.state.password1 === this.state.password2) {
              this.setState({
                passwordValid: true,
                passwordMessage: undefined
              });
            } else {
              this.setState({
                passwordValid: false,
                passwordMessage:
                  "Password must be more than 6 characters, and both versions must match"
              });
            }
          });
        } else {
          this.setState({ password2: event.target.value });
        }
        break;
      default:
        break;
    }
  };
  render() {
    return (
      <div className="container registerForm animated fadeIn">
        <div className="d-flex justify-content-center h-100">
          <div className="user_card">
            <div className="d-flex justify-content-center">
              <div className="brand_logo_container">
                <img src={logo} className="brand_logo" alt="Logo" />
              </div>
            </div>
            <div className="d-flex justify-content-center form_container">
              <form onSubmit={this.registerUser}>
                <div className="input-group mb-3">
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <i className="fas fa-user"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    name="firstName"
                    className="form-control input_user"
                    value={this.state.firstName}
                    onChange={this.onChange}
                    placeholder="first name"
                  />
                </div>
                <div className="input-group mb-3">
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <i className="fas fa-user"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    name="lastName"
                    className="form-control input_user"
                    value={this.state.lastName}
                    onChange={this.onChange}
                    placeholder="last name"
                  />
                </div>
                <div className="input-group mb-3">
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <i className="fas fa-envelope"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    name="username"
                    className="form-control input_user"
                    value={this.state.username}
                    onChange={this.onChange}
                    placeholder="email"
                  />
                </div>
                {this.state.emailMessage !== undefined ? (
                  <p className="alert">{this.state.emailMessage}</p>
                ) : (
                  ""
                )}
                <div className="input-group mb-2">
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <i className="fas fa-key"></i>
                    </span>
                  </div>
                  <input
                    type="password"
                    name="password1"
                    className="form-control input_pass"
                    value={this.state.password1}
                    onChange={this.onChange}
                    placeholder="password"
                  />
                </div>
                <div className="input-group mb-2">
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <i className="fas fa-key"></i>
                    </span>
                  </div>
                  <input
                    type="password"
                    name="password2"
                    className="form-control input_pass"
                    value={this.state.password2}
                    onChange={this.onChange}
                    placeholder="confirm password"
                  />
                </div>
                {this.state.passwordMessage !== undefined ? (
                  <p className="alert">{this.state.passwordMessage}</p>
                ) : (
                  ""
                )}
                <div className="d-flex justify-content-center mt-3 login_container">
                  {this.state.loading ? (
                    <i className="spinner fas fa-circle-notch fa-spin" />
                  ) : (
                    <button
                      type="submit"
                      name="button"
                      className="btn login_btn"
                      disabled={
                        this.state.passwordValid && this.state.emailValid
                          ? false
                          : true
                      }
                    >
                      Register Account
                    </button>
                  )}
                </div>

                <br />

                {this.state.message !== undefined ? (
                  <p className="alert">{this.state.message}</p>
                ) : (
                  ""
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
