import React, { Component } from "react";
import PostImageUploadUtility from "./PostImageUploadUtility";
import LoadingScreen from "../../../../common/LoadingScreen";
import Alert from "../../../../common/Alert";
import "./news.css";

export default class AddPost extends Component {
  constructor(props) {
    super();
    this.state = {
      postTitle: "",
      postContent: "",
      valid: true,
      loading: true,
      postID: "",
    };
  }
  onChange = (event) => {
    console.log("inside onchange");

    this.setState({ [event.target.name]: event.target.value });
  };
  setPostID = () => {
    if (this.state.postID !== "") {
      this.setState({ loading: false });
      return;
    } else {
      let ref = this.props.firebase.db
        .collection("site")
        .doc("news")
        .collection("posts")
        .doc();
      let id = ref.id;
      let date = new Date();
      let time = date.getTime();
      let post = { id: id, title: "", content: "", time: time };
      ref.set(post);
      console.log("inside addPost, id is : " + id);
      this.setState({ postID: id, loading: false, time: time });
    }
  };
  componentDidMount() {
    this.setPostID();
  }
  closeMessage = () => {
    this.setState({ message: undefined, messageType: undefined });
  };
  startUpload = () => {
    this.setState({ valid: false });
  };
  finishUploadImages = () => {
    console.log("not sure why I needed this, finished uploading images");
    this.setState({
      message: "Successfully Uploaded Photos For This Post.",
      messageType: "success",
      valid: true,
    });
  };
  savePost = () => {
    let post = {
      id: this.state.postID,
      title: this.state.postTitle,
      content: this.state.postContent,
      time: this.state.time,
    };
    this.props.firebase.db
      .collection("site")
      .doc("news")
      .collection("posts")
      .doc(this.state.postID)
      .set(post)
      .then(
        function (res) {
          console.log("successfully saved post");
          this.props.finish();
        }.bind(this)
      )
      .catch(
        function (err) {
          console.log("error setting post to database: " + err.message);
          this.setState({
            message:
              "There Was An Error Setting The Post To The Database. Copy Your Information, Refresh, Try Again.",
            messageType: "danger",
          });
        }.bind(this)
      );
  };
  render() {
    let message;
    if (this.state.message !== undefined) {
      let messageType;
      if (
        this.state.messageType == undefined ||
        this.state.messageType == null
      ) {
        messageType = "danger";
      } else {
        messageType = this.state.messageType;
      }
      message = (
        <Alert
          closeMessage={this.closeMessage}
          alertType={messageType}
          message={this.state.message}
        />
      );
    } else {
      message = "";
    }
    return (
      <div className="addPost">
        {this.state.loading ? (
          <LoadingScreen />
        ) : (
          <div>
            <h1 className="display-4">Add Post</h1>
            <div className="container">
              {message}
              <div className="row">
                <div className="col col-xs-12">
                  <p className="lead">Post Title:</p>
                </div>
              </div>
              <div className="row">
                <div className="col col-xs-12">
                  <input
                    type="text"
                    value={this.state.postTitle}
                    onChange={this.onChange}
                    name="postTitle"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col col-xs-12">
                  <p className="lead">Post Content:</p>
                </div>
              </div>
              <div className="row">
                <div className="col col-xs-12">
                  <textarea
                    type="text"
                    value={this.state.postContent}
                    onChange={this.onChange}
                    name="postContent"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col col-xs-12">
                  <div className="border imageBorder">
                    <p className="lead">Post Images</p>
                    <PostImageUploadUtility
                      postID={this.state.postID}
                      finish={this.finishUploadImages}
                      startUpload={this.startUpload}
                    />
                  </div>
                </div>
              </div>
              <button
                className="btn btn-sm btn-secondary dashboardButton"
                onClick={this.savePost}
                disabled={this.state.valid ? false : true}
              >
                Save Post
              </button>
              {message}
            </div>
          </div>
        )}
      </div>
    );
  }
}
