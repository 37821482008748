import React from "react";

export default function NavSettings(props) {
  console.log("inside navSettings, textColor is : " + props.textColor);
  return (
    <div>
      {" "}
      {props.loading ? (
        <i className="spinner fas fa-circle-notch fa-spin" />
      ) : (
        <div className="dashboardBlock container">
          <h1 className="display-5">Nav</h1>
          {props.message !== undefined ? (
            <p className="alert alert-primary">{props.message}</p>
          ) : (
            ""
          )}
          <div className="row">
            {" "}
            <div className="col col-md-3">
              {" "}
              <p className="lead">Nav Style:</p>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="relative"
                  onChange={() => {
                    props.answerNavStyle("relative");
                  }}
                  checked={props.navStyle == "relative" ? true : false}
                  value="relative"
                />
                <label className="form-check-label" htmlFor="relative">
                  Relative
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sticky"
                  onChange={() => {
                    props.answerNavStyle("sticky");
                  }}
                  checked={props.navStyle == "sticky" ? true : false}
                  value="sticky"
                />
                <label className="form-check-label" htmlFor="sticky">
                  Sticky
                </label>
              </div>
            </div>
            <div className="col col-md-3">
              {" "}
              <p className="lead">Logo Placement:</p>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="left"
                  onChange={() => {
                    props.answerLogoPlacement("left");
                  }}
                  checked={props.logoPlacement == "left" ? true : false}
                  disabled={props.navStyle == "sticky" ? true : false}
                  value="left"
                />
                <label className="form-check-label" htmlFor="left">
                  Left
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="center"
                  disabled={props.navStyle == "sticky" ? true : false}
                  onChange={() => {
                    props.answerLogoPlacement("center");
                  }}
                  checked={props.logoPlacement == "center" ? true : false}
                  value="center"
                />
                <label className="form-check-label" htmlFor="center">
                  Center
                </label>
              </div>
            </div>
            <div className="col col-md-3">
              {" "}
              <p className="lead">Add Depth To NavBar?</p>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="yes"
                  onChange={() => {
                    props.answerNavBarDepth(true);
                  }}
                  checked={props.navBarDepth ? true : false}
                  value="yes"
                />
                <label className="form-check-label" htmlFor="yes">
                  yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="no"
                  onChange={() => {
                    props.answerNavBarDepth(false);
                  }}
                  checked={props.navBarDepth ? false : true}
                  value="no"
                />
                <label className="form-check-label" htmlFor="no">
                  no
                </label>
              </div>
            </div>
            <div className="col col-md-3">
              <p className="lead">Text/Link Color:</p>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="light"
                  onChange={() => {
                    props.answerTextColor("light");
                  }}
                  checked={props.textColor == "light" ? true : false}
                  value="light"
                />
                <label className="form-check-label" htmlFor="light">
                  light
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="dark"
                  onChange={() => {
                    props.answerTextColor("dark");
                  }}
                  checked={
                    props.textColor == "dark" || props.textColor == undefined
                      ? true
                      : false
                  }
                  value="dark"
                />
                <label className="form-check-label" htmlFor="dark">
                  dark
                </label>
              </div>
            </div>
          </div>

          {props.loading ? (
            <button className="btn btn-secondary btn-sm submit" disabled>
              <i className="spinner fas fa-circle-notch fa-spin" />
            </button>
          ) : (
            <button
              className="btn btn-secondary btn-sm submit"
              onClick={props.saveNavSettings}
            >
              Save Nav Settings
            </button>
          )}
        </div>
      )}
    </div>
  );
}
