import React, { Component } from "react";
import FileUploadUtility from "./FileUploadUtility";
import Tabs from "./Tabs";

export default class Upload extends Component {
  render() {
    return (
      <div className="dashboardPanel">
        <h1 className="display-5">Upload</h1>
        <Tabs active="upload" />
        <div className="dashboardBlock container">
          {" "}
          <div className="row">
            <div className="col">
              {" "}
              <FileUploadUtility />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
