import React, { Component } from "react";
import logo from "./../../assets/logo.png";
import RelativeNav from "./RelativeNav/RelativeNav";
import StickyNav from "./StickyNav/StickyNav";
import { withFirebase } from "./../../firebase/index";
import { compose } from "redux";
import "./nav.css";
import LoadingScreen from "../../common/LoadingScreen";

class NavBar extends Component {
  constructor() {
    super();
    this.state = {
      logoPosition: "left",
      navType: "relative",
      loading: true,
    };
  }
  logOut = () => {
    this.props.firebase.auth
      .signOut()
      .then(function (res) {
        this.props.history.push("/login");
      })
      .catch(function (err) {
        console.log("error logging out: " + err.message);
      });
  };
  setNavSettings = () => {
    console.log(
      "inside setNavSettings: type is: " + JSON.stringify(this.props.nav)
    );
    this.setState({
      pages: this.props.nav.pages,
      logoPosition: this.props.nav.logoPlacement,
      navType: this.props.nav.style,
      boxShadow: this.props.nav.boxShadow,
      backgroundColor: this.props.nav.backgroundColor,
      textColor: this.props.nav.textColor,
      loading: false,
    });
  };
  componentDidMount() {
    this.setNavSettings();
  }
  render() {
    console.log("inside NavBar, boxShadow is: " + this.state.boxShadow);
    return (
      <div>
        {this.state.loading ? (
          <LoadingScreen />
        ) : (
          <div
            className="navBarContainer"
            style={
              this.state.boxShadow
                ? { boxShadow: "5px 10px 10px -10px rgba(0,0,0,.8)" }
                : {}
            }
          >
            {this.state.navType == "relative" ? (
              <RelativeNav
                logOut={() => {
                  this.logOut();
                }}
                loggedIn={this.props.loggedIn}
                admin={this.props.admin}
                logo={logo}
                navType={this.state.navType}
                textColor={this.state.textColor}
                colors={this.props.colors}
                logoPosition={this.state.logoPosition}
                pages={this.state.pages}
              />
            ) : (
              <div>
                <StickyNav
                  logOut={() => {
                    this.logOut();
                  }}
                  loggedIn={this.props.loggedIn}
                  admin={this.props.admin}
                  logo={logo}
                  navType={this.state.navType}
                  textColor={this.state.textColor}
                  colors={this.props.colors}
                  logoPosition={this.state.logoPosition}
                  pages={this.state.pages}
                />
                <div className="stickySpan"></div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(NavBar);
