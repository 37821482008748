export const version = "dev";

export const admin = ["kozbrett@gmail.com", "fremontbeekeepers@gmail.com"];
const fremontFirebaseConfig = {
  apiKey: "AIzaSyBeVduLJ4fyAn_fk5RxDTJnm5P8R3zjTNc",
  authDomain: "fremont-area-beeks.firebaseapp.com",
  projectId: "fremont-area-beeks",
  storageBucket: "fremont-area-beeks.appspot.com",
  messagingSenderId: "701056730300",
  appId: "1:701056730300:web:e6344ed0d6d39832f2bcb9",
};
const muskegonFirebaseConfig = {
  apiKey: "AIzaSyAl4mNFuvutmp9YO1PNN2mnxm6xLaXghbw",
  authDomain: "muskegonareabeeks.firebaseapp.com",
  projectId: "muskegonareabeeks",
  storageBucket: "muskegonareabeeks.appspot.com",
  messagingSenderId: "715255329468",
  appId: "1:715255329468:web:c6f1511668e4aee55f56b9",
};
const devFirebaseConfig = {
  apiKey: "AIzaSyDaXazsWm9CssWW3H-AVAYduD6NuZtttjw",
  authDomain: "bee-club.firebaseapp.com",
  databaseURL: "https://bee-club.firebaseio.com",
  projectId: "bee-club",
  storageBucket: "bee-club.appspot.com",
  messagingSenderId: "1046806760457",
  appId: "1:1046806760457:web:a00644c1220c948770fcd8",
};
const muskegonSendMail =
  "https://us-central1-muskegonareabeeks.cloudfunctions.net/sendMail";
const fremontSendMail =
  "https://us-central1-fremont-area-beeks.cloudfunctions.net/sendMail";
export const siteName = "Fremont Area Beekeepers";
export const firebaseConfig = fremontFirebaseConfig;
export const sendMail = fremontSendMail;

export const contactEmail = "fremontbeekeepers@gmail.com";
function checkVersion(method) {
  switch (method) {
    case "firebaseConfig":
      if (version == "dev") {
        return muskegonFirebaseConfig;
      } else {
        return;
      }
      break;
    default:
      break;
  }
}
