import React, { Component } from "react";
import { compose } from "redux";
import { withFirebase } from "./../../../firebase/index";
import NavSettings from "./NavSettings";
import Colors from "./Colors";
import "./global.css";

class Global extends Component {
  constructor() {
    super();
    this.state = {
      navStyle: "relative",
      logoPlacement: "left",
      navLoading: true,
      navMessage: undefined,
      navDepth: false,
      navBackgroundColor: "inherit",
      textColor: "dark",
      loading: true,
      pages:{
        home: { enabled: true },
        about: { enabled: true },
        events: { enabled: true },
        news: { enabled: true },
        members: { enabled: false },
        contact: { enabled: true },
        resources: { enabled: false },
        swarms: { enabled: false },
        marketplace: { enabled: false },
        gallery: { enabled: false },
        moreLink: { enabled: true },
      }
    };
  }
  getSiteColors = () => {
    this.props.firebase.db
      .collection("site")
      .doc("colors")
      .get()
      .then(
        function (res) {
          let colors = res.data();
          this.setState({
            primaryColor: colors.primaryColor,
            secondaryColor: colors.secondaryColor,
            backgroundColor: colors.backgroundColor,
            buttonColor: colors.buttonColor,
          });
        }.bind(this)
      )
      .catch(function (err) {
        console.log("error getting site colors: " + err.message);
      });
  };
  getNavSettings = () => {
    console.log("inside getNavSettings");
    this.props.firebase.db
      .collection("site")
      .doc("nav")
      .get()
      .then(
        function (res) {
          console.log("got nav settings, setting state");
          let style, logoPlacement, navDepth, backgroundColor, textColor, pages;
          let navObj = res.data();
          if (navObj.style !== undefined) {
            style = navObj.style;
          } else {
            style = "relative";
          }
          if (navObj.pages !== undefined) {
            pages = navObj.pages;
          } else {
            pages = {
              home: { enabled: true },
              about: { enabled: true },
              events: { enabled: true },
              news: { enabled: true },
              members: { enabled: false },
              contact: { enabled: true },
              resources: { enabled: false },
              swarms: { enabled: false },
              marketplace: { enabled: false },
              gallery: { enabled: false },
              moreLink: { enabled: true },
            };
          }
          if (navObj.logoPlacement !== undefined) {
            logoPlacement = navObj.logoPlacement;
          } else {
            logoPlacement = "left";
          }
          if (navObj.boxShadow !== undefined) {
            navDepth = navObj.boxShadow;
          }
          if (navObj.backgroundColor !== undefined) {
            backgroundColor = navObj.backgroundColor;
          }
          if (navObj.textColor !== undefined) {
            console.log("textColor isnt undefined: " + navObj.textColor);
            textColor = navObj.textColor;
          } else {
            console.log("textColor is undefined");
            textColor = "dark";
          }
          this.setState({
            navStyle: style,
            logoPlacement: logoPlacement,
            navDepth: navDepth,
            navBackgroundColor: backgroundColor,
            navLoading: false,
            textColor: textColor,
            pages: pages,
            loading: false,
          });
        }.bind(this)
      )
      .catch(
        function (err) {
          console.log("error getting nav settings: " + err.message);
          this.setState({ navLoading: false, loading: false });
        }.bind(this)
      );
  };
  answerNavStyle = (style) => {
    console.log("inside answer nav style");
    if (style === "sticky") {
      //set placement to left
      this.answerLogoPlacement("left");
    }
    this.setState({ navStyle: style });
  };
  answerNavBarDepth = (answer) => {
    console.log("inside answerNavBarDepth");

    this.setState({ navDepth: answer });
  };
  answerNavBackgroundColor = (color) => {
    console.log("inside answerNavBackgroundColor");
    this.setState({ navBackgroundColor: color });
  };
  answerTextColor = (color) => {
    console.log("inside answerTextColor: " + color);
    this.setState({ textColor: color });
  };
  answerLogoPlacement = (placement) => {
    console.log("inside answerlogoplacement");
    this.setState({ logoPlacement: placement });
  };
  saveNavSettings = () => {
    console.log("inside saveNavSettings");
    this.setState({ navLoading: true });
    let nav = {
      style: this.state.navStyle,
      logoPlacement: this.state.logoPlacement,
      boxShadow: this.state.navDepth,
      backgroundColor: this.state.navBackgroundColor,
      pages: this.state.pages,
      textColor: this.state.textColor,
    };
    this.props.firebase.db
      .collection("site")
      .doc("nav")
      .set(nav)
      .then(
        function (res) {
          this.setState({
            navLoading: false,
            navMessage: "Nav Settings Successfully Saved",
          });
        }.bind(this)
      )
      .catch(
        function (err) {
          console.log("error writing nav settings: " + err.message);
          this.setState({
            navLoading: false,
            navMessage:
              "Error Saving Nav Settings. Err Message: " + err.message,
          });
        }.bind(this)
      );
  };
  saveSiteColors = () => {
    this.setState({ colorsLoading: true });
    console.log("saving site colors");
    let primaryColor, secondaryColor, backgroundColor, buttonColor;
    if (this.state.primaryColor == undefined) {
      primaryColor = "#ffffff";
    } else {
      primaryColor = this.state.primaryColor;
    }
    if (this.state.secondaryColor == undefined) {
      secondaryColor = "#000000";
    } else {
      secondaryColor = this.state.secondaryColor;
    }
    if (this.state.backgroundColor == undefined) {
      backgroundColor = "#ffffff";
    } else {
      backgroundColor = this.state.backgroundColor;
    }
    if (this.state.buttonColor == undefined) {
      buttonColor = "#6c757d";
    } else {
      buttonColor = this.state.buttonColor;
    }
    let colors = {
      primaryColor: primaryColor,
      secondaryColor: secondaryColor,
      buttonColor: buttonColor,
      backgroundColor: backgroundColor,
    };
    this.props.firebase.db
      .collection("site")
      .doc("colors")
      .set(colors)
      .then(
        function (res) {
          console.log("success setting site colors");
          this.setState({
            colorsLoading: false,
            colorsMessage: "Successfully Saved Site Colors",
          });
        }.bind(this)
      )
      .catch(
        function (err) {
          console.log("error writing site colors: " + err.message);
          this.setState({
            colorsLoading: false,
            colorsMessage:
              "Error Saving Site Colors. Err Message: " + err.message,
          });
        }.bind(this)
      );
  };
  answerPrimaryColor = (color) => {
    console.log("answerPrimaryColor");
    this.setState({ primaryColor: color });
  };
  answerSecondaryColor = (color) => {
    this.setState({ secondaryColor: color });
  };
  answerBackgroundColor = (color) => {
    this.setState({ backgroundColor: color });
  };
  answerButtonColor = (color) => {
    this.setState({ buttonColor: color });
  };
  setDefaultColors = () => {
    this.setState({
      primaryColor: "#ffffff",
      secondaryColor: "#000000",
      buttonColor: "#6c757d",
      backgroundColor: "#ffffff",
    });
  };
  componentDidMount = () => {
    this.getSiteColors();
    this.getNavSettings();
  };
  render() {
    console.log("from render, textColor is : " + this.state.textColor);
    return (
      <div className="dashboardPanel">
        <h1 className="display-5">Global Site Settings</h1>
        <hr />
        <NavSettings
          navStyle={this.state.navStyle}
          answerNavStyle={this.answerNavStyle}
          logoPlacement={this.state.logoPlacement}
          answerLogoPlacement={this.answerLogoPlacement}
          loading={this.state.navLoading}
          saveNavSettings={this.saveNavSettings}
          answerBackgroundColor={this.answerNavBackgroundColor}
          answerNavBarDepth={this.answerNavBarDepth}
          navBarDepth={this.state.navDepth}
          backgroundColor={this.state.navBackgroundColor}
          message={this.state.navMessage}
          answerTextColor={this.answerTextColor}
          textColor={this.state.textColor}
        />
        <hr />
        <Colors
          message={this.state.colorsMessage}
          saveSiteColors={this.saveSiteColors}
          loading={this.state.colorsLoading}
          answerPrimaryColor={this.answerPrimaryColor}
          answerSecondaryColor={this.answerSecondaryColor}
          answerBackgroundColor={this.answerBackgroundColor}
          answerButtonColor={this.answerButtonColor}
          primaryColor={this.state.primaryColor}
          secondaryColor={this.state.secondaryColor}
          backgroundColor={this.state.backgroundColor}
          buttonColor={this.state.buttonColor}
          setDefaultColors={this.setDefaultColors}
        />
      </div>
    );
  }
}

export default compose(withFirebase)(Global);
