import React from "react";

export default function SiteImageContainer(props) {
  console.log(
    "inside siteImageContainer, adding " + props.siteImages.length + " photos"
  );

  let disabled;
  if (props.disabled == undefined) {
    disabled = false;
  } else {
    disabled = props.disabled;
  }
  console.log("disabled is: " + disabled);
  return (
    <div>
      {" "}
      {props.siteImages.map((file, i) => (
        <div
          className={
            file.selected == true
              ? "selectedSiteImage imgToUploadThumb col col-xs-4 col-md-4"
              : "imgToUploadThumb col col-xs-4 col-md-4"
          }
          onClick={() => {
            if (!disabled) {
              props.select(file);
            }
          }}
          key={i}
        >
          <img
            className={
              props.selectedImage == props.siteImages[i]
                ? "selectedSiteImage"
                : "notSelectedImage"
            }
            src={props.siteImages[i].url}
          ></img>
          <br />
        </div>
      ))}
    </div>
  );
}
