import React, { Component } from "react";
import { withFirebase } from "../../firebase";
import { compose } from "redux";
import FeatureEventView from "./FeatureEventView";

class FeatureEvent extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      event: {},
      noEvent: false,
    };
  }
  sortEvents = (events) => {
    console.log("inside sortEvents");
    let now = new Date();
    let nowTime = now.getTime();
    let newEvents = [];
    events.forEach((eventObj, i) => {
      console.log("creatingNewEvent to get differential");
      let newEvent = eventObj;
      let differential = eventObj.start.datetime - nowTime;
      console.log("differential is: " + differential);
      newEvent.differential = differential;
      newEvents.push(newEvent);
    });
    console.log("set newEvents, now to sorts");
    newEvents.sort(function (a, b) {
      return parseFloat(a.differential) - parseFloat(b.differential);
    });

    let positiveEvents = [];
    newEvents.forEach((eventObj2, i) => {
      console.log("looking for positive differential");
      if (eventObj2.differential < 0) {
        console.log("negative differential, returning");
        return;
      } else if (eventObj2.differential > 0) {
        console.log(
          "positive differential, should be good with: " +
            eventObj2.differential
        );
        positiveEvents.push(eventObj2);
      }
    });
    console.log(
      "after sorting, event is: " + JSON.stringify(positiveEvents[0])
    );
    if (positiveEvents[0] == undefined) {
      console.log("no event!");
      this.setFields(false);
    } else {
      this.setFields(positiveEvents[0]);
    }
  };
  getLatestEvent = () => {
    console.log("inside getLatestEvent");
    this.props.firebase.db
      .collection("site")
      .doc("events")
      .collection("events")
      .get()
      .then(
        function (res) {
          let events = [];
          res.forEach((eventObj, i) => {
            console.log("got event");
            events.push(eventObj.data());
          });
          this.sortEvents(events);
        }.bind(this)
      )
      .catch(function (err) {
        console.log("error getting events: " + err.message);
      });
  };
  getEvent = () => {
    if (this.props.feature.content == "latest") {
      console.log("calling get latest event");
      this.getLatestEvent();
    } else {
      this.setFields(this.props.feature.content);
    }
  };
  setFields = (event) => {
    if (event === false) {
      this.setState({ noEvent: true, loading: false, event: {} });
    } else {
      this.setState({ event: event, loading: false, noEvent: false });
    }
  };
  componentDidMount() {
    console.log("inside eventFeature: " + JSON.stringify(this.props.feature));
    this.getEvent();
  }
  render() {
    return (
      <div className="featureContainer">
        {this.state.loading ? (
          <i className="spinner fas fa-circle-notch fa-spin" />
        ) : (
          <div className="featureContainer">
            {this.state.noEvent ? (
              "No Upcoming Events"
            ) : (
              <FeatureEventView event={this.state.event} />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(FeatureEvent);
