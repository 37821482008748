import React from "react";

export default function EventsLocationTable(props) {
  return (
    <div className="clientsTableContainer">
      <table className="table table-hover">
        <thead className="thead-dark">
          <tr>
            <th></th>
            <th className="sort" scope="col">
              Name
            </th>
            <th className="sort" scope="col">
              Address
            </th>
            <th className="sort" scope="col">
              City
            </th>
            <th className="sort" scope="col">
              State
            </th>
          </tr>
        </thead>
        <tbody>
          {props.locations.map((location, i) => (
            <tr key={i}>
              <td>
                <input
                  type="checkbox"
                  checked={location.selected ? true : false}
                  id="selectLocation"
                  onClick={() => {
                    props.selectLocation(location);
                  }}
                ></input>
              </td>
              <th scope="row">{location.name}</th>
              <th scope="row">{location.address}</th>
              <th scope="row">{location.city}</th>
              <th scope="row"></th>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
