import React, { Component } from "react";
import LoadingScreen from "../../../../../common/LoadingScreen";
import EventsTable from "./../../Events/EventsTable";

export default class AddFeatureEvent extends Component {
  constructor() {
    super();
    this.state = { events: [], loading: true, selectedEvent: undefined };
  }
  selectEvent = (eventObj1) => {
    let events = [...this.state.events];
    let selectedEvent = undefined;
    events.forEach((eventObj2, i) => {
      if (eventObj1 == eventObj2) {
        if (eventObj2.selected) {
          eventObj2.selected = false;
        } else {
          eventObj2.selected = true;
          selectedEvent = eventObj2;
        }
      } else {
        eventObj2.selected = false;
      }
    });
    this.setState({ events: events, selectedEvent: selectedEvent });
  };
  getEvents() {
    this.props.firebase.db
      .collection("site")
      .doc("events")
      .collection("events")
      .get()
      .then(
        function (res) {
          let events = [];
          res.forEach((eventObj, i) => {
            events.push(eventObj.data());
          });
          this.setState({ events: events, loading: false });
        }.bind(this)
      )
      .catch(function (err) {
        console.log("error getting events: " + err.message);
      });
  }
  componentDidMount() {
    this.getEvents();
  }
  submit = (featureObj) => {
    console.log("inside submit");
    let feature;
    if (feature == "latest") {
      console.log("submitting latest event");
      feature = { type: "event", content: "latest" };
      this.props.finish(feature);
    } else {
      console.log("submitting specific event: " + JSON.stringify(featureObj));
      feature = { type: "event", content: featureObj };
      this.props.submit(feature);
    }
  };
  render() {
    return (
      <div className="addFeatureEvent">
        {this.state.loading ? (
          <LoadingScreen />
        ) : (
          <div>
            Add Event:
            <br />
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                this.submit("latest");
              }}
            >
              Add Soonest Event (Continual)
            </button>
            {this.state.selectedEvent !== undefined ? (
              <button
                className="btn btn-sm btn-primary"
                onClick={() => {
                  this.submit(this.state.selectedEvent);
                }}
              >
                Add Selected Event
              </button>
            ) : (
              ""
            )}
            <EventsTable
              events={this.state.events}
              selectEvent={this.selectEvent}
            />
          </div>
        )}
      </div>
    );
  }
}
