import React from "react";
import RelativeLogoLeft from "./RelativeLogoLeft";
import RelativeLogoCenter from "./RelativeLogoCenter";

export default function RelativeNav(props) {
  console.log("inside relativeNav, loggedIn is : " + props.loggedIn);
  return (
    <div className="navBar relativeNav">
      {props.logoPosition == "left" ? (
        <RelativeLogoLeft
          navType={props.navType}
          textColor={props.textColor}
          loggedIn={props.loggedIn}
          colors={props.colors}
          admin={props.admin}
          logOut={props.logOut}
          logo={props.logo}
          pages={props.pages}
        />
      ) : (
        <RelativeLogoCenter
          navType={props.navType}
          loggedIn={props.loggedIn}
          textColor={props.textColor}
          colors={props.colors}
          admin={props.admin}
          logOut={props.logOut}
          logo={props.logo}
          pages={props.pages}
        />
      )}
    </div>
  );
}
