import React, { Component } from "react";
import logo from "../../../assets/logo.png";
import "./toolbar.css";

import DrawerToggleButton from "./DrawerToggleButton";
import SideDrawer from "./SideDrawer";
import Backdrop from "./../../../common/Backdrop";

class Toolbar extends Component {
  constructor() {
    super();
    this.state = {
      drawerToggle: false,
      moreOptionsToggle: false
    };
    this.onDrawerToggleClick = this.onDrawerToggleClick.bind(this);
    this.onBackdropClick = this.onBackdropClick.bind(this);
    this.onLogoutClick = this.onLogoutClick.bind(this);
    this.onAccountSettingsClick = this.onAccountSettingsClick.bind(this);
  }
  onLogoutClick() {
    this.props.logOutUser();
  }
  onAccountSettingsClick() {}
  onDrawerToggleClick() {
    console.log("onDrawerToggleClick");
    this.setState(prevState => {
      return { drawerToggle: !prevState.drawerToggle };
    });
  }
  onBackdropClick() {
    console.log("onBackdropClick");
    this.setState(prevState => {
      return { drawerToggle: false };
    });
  }

  onMoreOptionsClick() {
    console.log("onMoreOptionsClick");
    this.setState(prevState => {
      return { moreOptionsToggle: !prevState.moreOptionsToggle };
    });
  }
  render() {
    let backdrop, sideDrawer, moreOptionsMenu;
    if (this.state.drawerToggle) {
      backdrop = <Backdrop click={this.onBackdropClick} />;
      sideDrawer = (
        <SideDrawer logOut={this.props.logOut} click={this.onBackdropClick} />
      );
    } else {
      backdrop = "";
      sideDrawer = "";
    }
    if (this.state.moreOptionsToggle) {
      moreOptionsMenu = (
        <ul className="moreOptionsList sideDrawerAnimated slideInRight">
          <li>
            <button
              className="toolbarBtn btn btn-link"
              onClick={() => {
                this.onAccountSettingsClick();
              }}
            >
              Account
            </button>
          </li>
          <li>
            <button
              className="toolbarBtn btn btn-link"
              onClick={() => {
                this.onLogoutClick();
              }}
            >
              Log Out
            </button>
          </li>
        </ul>
      );
    }
    return (
      <div>
        <div className="toolbar">
          <nav className="toolbar_navigation secondaryBackground">
            <div>
              <DrawerToggleButton toggle={this.onDrawerToggleClick} />
            </div>
            <div className="toolbarLogo">
              <img style={{ width: 60 }} src={logo} />
              <h3 className="display-5">Site Admin</h3>
            </div>

            <div className="toolbarLinks"> </div>
          </nav>
        </div>
        {sideDrawer}
        {backdrop}
      </div>
    );
  }
}

export default Toolbar;
