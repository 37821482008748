import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/logo.png";
import "./sideDrawer.css";

export default function SideDrawer(props) {
  return (
    <nav
      style={{ backgroundColor: "rgba(88, 88, 88, 1)" }}
      className="sideDrawer secondaryBackground sideDrawerAnimated animated slideInLeft"
    >
      <div className="navbar-brand brandColorText ">
        <img
          src={logo}
          className="d-inline-block align-top logo img-responsive sideDrawerLogo"
          alt="Muskegon Area Beekeepers"
        />
      </div>
      <ul>
        <li className="firstListItem">
          <Link
            to="/admin-dashboard"
            onClick={() => {
              props.click();
            }}
            className="drawerNavLink"
          >
            <i className="fas fa-home drawerIcon" />
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/admin-dashboard/global"
            onClick={() => {
              props.click();
            }}
            className="drawerNavLink"
          >
            <i className="fas fa-globe-americas drawerIcon"></i>
            Global Settings
          </Link>
        </li>
        <li>
          <Link
            to="/admin-dashboard/pages"
            onClick={() => {
              props.click();
            }}
            className="drawerNavLink"
          >
            <i className="far fa-file drawerIcon"></i>
            Pages
          </Link>
        </li>
        <li>
          <Link
            to="/admin-dashboard/events"
            onClick={() => {
              props.click();
            }}
            className="drawerNavLink"
          >
            <i className="fas fa-calendar-alt drawerIcon" />
            Events
          </Link>
        </li>
        <li>
          <Link
            to="/admin-dashboard/news"
            onClick={() => {
              props.click();
            }}
            className="drawerNavLink"
          >
            <i className="fas fa-newspaper drawerIcon" />
            News
          </Link>
        </li>
        <li>
          <Link
            to="/admin-dashboard/images"
            onClick={() => {
              props.click();
            }}
            className="drawerNavLink"
          >
            <i className="fas fa-images drawerIcon"></i>
            Images
          </Link>
        </li>
        <li>
          <Link
            to="/admin-dashboard/users"
            onClick={() => {
              props.click();
            }}
            className="drawerNavLink"
          >
            <i className="fas fa-users drawerIcon"></i>
            Users
          </Link>
        </li>
        <li>
          <Link
            to="/"
            onClick={() => {
              props.logOut();
              props.click();
            }}
            className="drawerNavLink"
          >
            <i className="fas fa-sign-out-alt drawerIcon"></i>
            Log Out
          </Link>
        </li>
        <li>
          <Link
            to="/"
            onClick={() => {
              props.click();
            }}
            className="drawerNavLink"
          >
            <i className="fas fa-bus drawerIcon"></i>
            Back To Public Site
          </Link>
        </li>
      </ul>
    </nav>
  );
}
