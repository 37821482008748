import React, { Component } from "react";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";
import UploadProgress from "./../../../common/UploadProgress";

class FileUploadUtility extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      valid: false,
      loading: true,
      selectedImage: undefined,
      message: "Ready To Upload " + 0 + " Images",
      uploading: false,
      uploadDestination: this.props.destination,
      clearStage: false
    };
  }
  selectImage = index => {
    this.setState({ selectedImage: this.state.files[index] });
  };

  submitUpload = event => {
    event.preventDefault();

    if (this.state.files.length < 1) {
      this.setState({ message: "Cannot Upload 0 photos" });
      return;
    } else {
      //upload them
      console.log("state from submit is : " + JSON.stringify(this.state.files));
      let filesProgress = this.state.files;
      filesProgress.forEach(file => {
        file.progress = 0;
      });
      this.setState({
        uploading: true,
        filesProgress: filesProgress,
        message: "Uploading"
      });
      for (let i = 0; i < this.state.files.length; i++) {
        if (i + 1 == this.state.files.length) {
          console.log("last one");
          this.uploadImage(
            this.state.files[i],
            this.state.files[i].name,
            this.state.files[i].caption,
            i,
            this.state.files.length
          )
            .then(
              function(res) {
                this.setState(
                  {
                    message:
                      "Success Uploading " + this.state.files.length + " Images"
                  },
                  () => {}
                );
              }.bind(this)
            )
            .catch(function(err) {});
        } else {
          this.uploadImage(
            this.state.files[i],
            this.state.files[i].name,
            this.state.files[i].caption,
            i,
            this.state.files.length
          );
        }
      }
    }
  };
  changeFileData = (event, target, i) => {
    console.log("changing file data");

    let files = [...this.state.files];
    console.log(JSON.stringify(files));
    switch (target) {
      case "caption":
        files[i].caption = event.target.value;

        break;
      case "name":
        files[i].name = event.target.value;
        break;
      default:
        break;
    }
    this.setState({ files: files });
  };
  clearAfterUpload = () => {
    this.setState({
      files: [],
      valid: false,
      loading: true,
      selectedImage: undefined,
      message: "Ready To Upload " + 0 + " Images",
      uploading: false,
      uploadDestination: this.props.destination,
      clearStage: false
    });
  };
  uploadImage = (file, name, caption, index, total) => {
    console.log(
      "INSIDE UPLOAD IMAGE: FILES TO UPLOAD ARE: " + this.state.files.length
    );

    return new Promise(
      function(res, rej) {
        let name, caption;
        if (file.name == "" || file.name == undefined) {
          name = "";
        } else {
          name = file.name;
        }
        if (file.caption == "" || file.caption == undefined) {
          caption = "";
        } else {
          caption = file.caption;
        }

        let time = new Date().getTime();
        let imageObject = {
          file: file,
          name: name,
          caption: caption,
          id: time + "" + name,
          time: time,
          type: "site"
        };

        const storagePath = "siteImages/" + time + name + "/" + name;
        const storageRef = this.props.firebase.storage.ref();
        imageObject.path = storagePath;
        let uploadTask = storageRef.child(storagePath).put(file.file);

        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        uploadTask.on(
          "state_changed",
          function(snapshot) {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            this.setUploadProgress(progress, index);
            //   switch (snapshot.state) {
            //     case firebase.storage.TaskState.PAUSED: // or 'paused'
            //       console.log("Upload is paused");
            //       break;
            //     case firebase.storage.TaskState.RUNNING: // or 'running'
            //       console.log("Upload is running");
            //       break;
            //   }
          }.bind(this),
          function(error) {
            // Handle unsuccessful uploads
            this.setState({ message: "Error Uploading: " + error.message });
            rej("error");
          },
          function() {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadTask.snapshot.ref.getDownloadURL().then(
              function(downloadURL) {
                console.log("File available at", downloadURL);
                imageObject.url = downloadURL;
                this.setImageToDB(imageObject)
                  .then(function(r) {
                    res("success");
                  })
                  .catch(function(err) {
                    console.log("err" + err);
                  });
              }.bind(this)
            );
          }.bind(this)
        );
      }.bind(this)
    );
  };
  setUploadProgress = (progress, index, total) => {
    let files = [...this.state.files];
    files[index].uploadProgress = progress;

    this.setState({ files: files }, () => {
      let prog = 0;
      this.state.files.forEach(file => {
        prog = prog + file.uploadProgress;
      });
      let total = prog / this.state.files.length;
      if (total === 100) {
        this.setState({ totalProgress: total, clearStage: true });
      } else {
        this.setState({ totalProgress: total });
      }

      console.log("total progress now is : " + total);
    });
  };
  setImageToDB = image => {
    return new Promise(
      function(response, rej) {
        console.log("inside setImageToDB, image is : " + JSON.stringify(image));
        let imageObject = {
          id: image.id,
          url: image.url,
          name: image.name,
          caption: image.caption,
          path: image.path,
          type: image.type,
          time: image.time
        };

        console.log("writing to db in site collection");
        this.props.firebase.db
          .collection("site-images")
          .doc(image.id)
          .set(imageObject)
          .then(res => {
            console.log("wrote image to db");
            response("done");
          })
          .catch(function(err) {
            console.log("error writing to db: " + err.message);
            rej(err.message);
          });
      }.bind(this)
    );
  };
  removeImage = e => {
    e.preventDefault();
    console.log("INSIDE REMOVEIMAGE");
    console.log("selected ");
    let files = this.state.files;
    files.splice(this.state.selectedImage, 1);
    this.setState({
      files: files,
      message: "Ready To Upload " + files.length + " Images"
    });
  };
  setFiles = event => {
    console.log("inside set files");
    console.log("files are " + JSON.stringify(event.target.files[0].name));
    let files = [...this.state.files];
    for (let i = 0; i < event.target.files.length; i++) {
      let file = {
        file: event.target.files[i],
        url: URL.createObjectURL(event.target.files[i]),
        name: event.target.files[i].name
      };
      files.push(file);
      //   let img = new Image();
      //   img.src = file.url;
      //   img.onload = function(file, files) {
      //     console.log("image details: " + img.height);
      //     file.width = img.width;
      //     file.height = img.height;
      //     files.push(file);
      //     if (i == event.target.files.length) {
      //       console.log("final");
      //     }
      //   };
    }
    this.setState({
      files: files,
      loading: false,
      valid: true,
      message: "Ready to Upload " + files.length + " Images"
    });
  };
  render() {
    return (
      <form onSubmit={this.submitUpload}>
        <div className="formElement">
          <div className="custom-file">
            <input
              type="file"
              className="custom-file-input"
              id="customFile"
              multiple="multiple"
              accept="image/*"
              disabled={
                this.state.uploading || this.state.clearStage ? true : false
              }
              onChange={this.setFiles}
            />

            <label className="custom-file-label" htmlFor="customFile">
              Choose Images
            </label>
          </div>
          {this.state.selectedImage !== undefined &&
          !this.state.clearStage &&
          !this.state.uploading ? (
            <button
              onClick={this.removeImage}
              className="btn btn-sm btn-secondary deleteImage"
              disabled={this.state.uploading ? true : false}
            >
              Remove Image
            </button>
          ) : (
            ""
          )}
          <div className="imgToUploadThumbContainer row">
            {this.state.loading
              ? ""
              : this.state.files.map((file, i) => (
                  <div
                    className="imgToUploadThumb col col-xs-6 col-md-4 col-lg-2"
                    onClick={() => {
                      this.selectImage(i);
                    }}
                    key={i}
                  >
                    <img
                      className={
                        this.state.selectedImage == this.state.files[i]
                          ? "selectedImage"
                          : "notSelectedImage"
                      }
                      src={this.state.files[i].url}
                    ></img>
                    <br />
                    <label htmlFor="fileName">File Name</label>
                    <input
                      disabled={
                        this.state.uploading || this.state.clearStage
                          ? true
                          : false
                      }
                      type="text"
                      name="fileName"
                      id="fileName"
                      onChange={e => {
                        this.changeFileData(e, "name", i);
                      }}
                      value={this.state.files[i].name}
                    />
                    <label htmlFor="fileName">Caption</label>
                    <input
                      disabled={
                        this.state.uploading || this.state.clearStage
                          ? true
                          : false
                      }
                      type="text"
                      name="fileCaption"
                      id="fileCaption"
                      onChange={e => {
                        this.changeFileData(e, "caption", i);
                      }}
                      value={this.state.files[i].caption}
                    />
                    {this.state.uploading ? (
                      <div>
                        <UploadProgress
                          percent={this.state.files[i].uploadProgress}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
          </div>
          <div className="formElement">
            <p className="label">{this.state.message}</p>
            {this.state.uploading ? (
              <div>
                <p className="lead">
                  Total Upload Progress Is:{" "}
                  {parseFloat(this.state.totalProgress).toFixed(2)}%
                </p>
                <UploadProgress percent={this.state.totalProgress} />
              </div>
            ) : (
              ""
            )}
            {this.state.clearStage && this.state.totalProgress === 100 ? (
              <button onClick={this.clearAfterUpload}>
                Clear After Upload
              </button>
            ) : (
              ""
            )}
            <br />
            <button
              disabled={
                this.state.uploading ||
                !this.state.valid ||
                this.state.clearStage
                  ? true
                  : false
              }
              type="submit"
              className="btn btn-lg btn-secondary"
            >
              Start Upload
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default compose(withFirebase)(FileUploadUtility);
