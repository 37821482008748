import React, { Component } from "react";
import LoadingScreen from "../../../common/LoadingScreen";
import Footer from "../../Footer";
import NavBar from "../../nav/NavBar";
import { siteName } from "./../../../versionInfo";
import { withFirebase } from "../../../firebase";
import { compose } from "redux";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import EventView from "./EventView";
import Backdrop from "../../../common/Backdrop";
import "./events.css";
// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = momentLocalizer(moment); // or globalizeLocalizer

class Events extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      events: [],
    };
  }
  getEvents = () => {
    console.log("getting events");
    this.props.firebase.db
      .collection("site")
      .doc("events")
      .collection("events")
      .get()
      .then(
        function (res) {
          let events = [];
          res.forEach((event, i) => {
            console.log("got event");
            events.push(event.data());
          });
          this.formatEvents(events);
        }.bind(this)
      )
      .catch(function (err) {
        console.log("error getting events: " + err.message);
      });
  };
  formatEvents = (events) => {
    console.log("formatting events");
    let formattedEvents = [];
    events.forEach((event, i) => {
      console.log("event being formatted: " + JSON.stringify(event));
      let newEvent = {
        start: new Date(event.start.datetime),
        end: new Date(event.end.datetime),
        starttime: event.start.datetime,
        endtime: event.end.datetime,
        title: event.name,
        description: event.description,
        location: {
          address: event.location.address,
          city: event.location.city,
        },
      };
      formattedEvents.push(newEvent);
    });
    this.setState({ events: formattedEvents, loading: false });
  };
  formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  setCurrentDate = () => {
    let d = new Date();
    let month = d.getMonth();
    let year = d.getFullYear();
    console.log("setting current date: " + month + "-" + year);
    this.setState({ month: month, year: year });
  };

  componentDidMount() {
    this.setCurrentDate();
    this.getEvents();
  }
  selectEvent = (event) => {
    console.log("selected event: " + JSON.stringify(event));
    this.setState({ eventView: true, selectedEvent: event });
  };
  cancelEventView = () => {
    this.setState({ eventView: false, selectedEvent: undefined });
  };
  render() {
    let styleObj = {
      backgroundColor: this.props.global.colors.backgroundColor,
      height: "100%",
      minHeight: "100%",
    };
    return (
      <div style={styleObj} className="pageContainer">
        {this.state.loading ? (
          <LoadingScreen />
        ) : (
          <div style={styleObj}>
            {" "}
            <NavBar
              nav={this.props.global.nav}
              colors={this.props.global.colors}
              loggedIn={this.props.signedIn}
              admin={this.props.admin}
            />
            <div className="container">
              <div className="row text-center">
                <h1
                  className="display-4 headline"
                  style={{ color: this.props.global.colors.secondaryColor }}
                >
                  {siteName} Events
                </h1>
                <Calendar
                  localizer={localizer}
                  defaultDate={new Date()}
                  defaultView="month"
                  events={this.state.events}
                  style={{ height: "100vh", width: "100%" }}
                  onSelectEvent={this.selectEvent}
                />
              </div>
            </div>
            <Footer />
          </div>
        )}
        {this.state.eventView ? (
          <div>
            <EventView event={this.state.selectedEvent} />
            <Backdrop click={this.cancelEventView} />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(Events);
