import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = (
  { component: Component, signedIn, admin, loading, global, ...rest },
  props
) => {
  let isAuthenticated = false;

  if (signedIn) {
    isAuthenticated = true;
  }

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component global={global} admin={admin} {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
