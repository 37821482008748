import React from "react";
import formatDate from "./../../../../common/formatDate";

export default function PostsTable(props) {
  return (
    <div className="clientsTableContainer">
      <h1 className="display-4">Posts</h1>
      <table className="table table-hover">
        <thead className="thead-dark">
          <tr>
            <th></th>
            <th className="sort" scope="col">
              Date
            </th>
            <th className="sort" scope="col">
              Title
            </th>
          </tr>
        </thead>
        <tbody>
          {props.posts.map((postObj, i) => (
            <tr key={i}>
              <td>
                <input
                  type="checkbox"
                  checked={props.selectedPost == postObj ? true : false}
                  id="selectPost"
                  onClick={() => {
                    props.selectPost(postObj);
                  }}
                ></input>
              </td>
              <th scope="row">{formatDate(postObj.time)}</th>
              <th scope="row">{postObj.title}</th>

              <th scope="row"></th>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
