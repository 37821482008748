import React, { Component } from "react";
import Footer from "../../Footer";
import LoadingScreen from "../../../common/LoadingScreen";
import NavBar from "../../nav/NavBar";
import Gallery from "react-grid-gallery";
import ImageGallery from "react-image-gallery";
import { withFirebase } from "../../../firebase";
import { compose } from "redux";
import { siteName } from "../../../versionInfo";
import "react-image-gallery/styles/css/image-gallery.css";

class GalleryPage extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      galleryObj: { images: [], type: "grid" },
    };
  }
  getGallery = () => {
    this.props.firebase.db
      .collection("site")
      .doc("gallery")
      .get()
      .then(
        function (res) {
          if (res.exists) {
            let galleryObj = res.data();
            console.log("gallery data: " + JSON.stringify(galleryObj));
            this.setState({ galleryObj: galleryObj, loading: false });
          }
        }.bind(this)
      )
      .catch(function (err) {
        console.log("error getting gallery from db: " + err.message);
      });
  };
  componentDidMount() {
    this.getGallery();
  }
  render() {
    let styleObj = {
      backgroundColor: this.props.global.colors.backgroundColor,
      height: "100%",
      minHeight: "100%",
    };
    let images = [];

    let gallery;
    if (this.state.galleryObj.type == "grid") {
      this.state.galleryObj.images.forEach(function (image) {
        let newImg = {
          src: image.url,
          thumbnail: image.thumbFive,
          thumbnailWidth: 500,
          thumbnailHeight: 500,
          caption: image.caption,
        };
        images.push(newImg);
      });
      gallery = <Gallery backdropClosesModal={true} images={images} />;
    } else if (this.state.galleryObj.type == "cinema") {
      this.state.galleryObj.images.forEach(function (image) {
        images.push({ original: image.url, thumbnail: image.thumbFive });
      });
      gallery = <ImageGallery items={images} />;
    }
    return (
      <div style={styleObj} className="pageContainer">
        {this.state.loading ? (
          <LoadingScreen />
        ) : (
          <div style={styleObj}>
            {" "}
            <NavBar
              nav={this.props.global.nav}
              colors={this.props.global.colors}
              loggedIn={this.props.signedIn}
              admin={this.props.admin}
            />
            <div className="container">
              <div className="row text-center">
                <h1
                  className="display-4 headline"
                  style={{ color: this.props.global.colors.secondaryColor }}
                >
                  {siteName} Gallery
                </h1>
              </div>
              {gallery}
            </div>
          </div>
        )}
        <Footer />
      </div>
    );
  }
}

export default compose(withFirebase)(GalleryPage);
