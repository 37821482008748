import React, { Component } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import GetParagraph from "../../../../common/GetParagraph";
import AddImage from "../../../../common/AddImage";
import Backdrop from "./../../../../common/Backdrop";
import { withFirebase } from "../../../../firebase";
import HomeParagraph from "./HomeParagraph";
import AddFeature from "./AddFeature/AddFeature";
import Alert from "./../../../../common/Alert";
import "./homeManagement.css";
import FeatureTable from "./AddFeature/FeatureTable";
import Confirmation from "../../../../common/Confirmation";
import EditFeature from "./AddFeature/EditFeature";

class HomeManagement extends Component {
  constructor() {
    super();
    this.state = {
      homeParagraph: "",
      loading: true,
      sliderImages: [],
      features: [],
    };
  }
  setSliderImage = (image) => {
    console.log(
      "inside setSliderImage: " + this.state.destination + JSON.stringify(image)
    );
    this.setState({ addImage: false });
    let images = [...this.state.sliderImages];
    let index = this.state.destination - 1;
    images[index] = image;
    this.setState({ sliderImages: images });
  };
  onHomeParagraphChange = (event) => {
    console.log("changing homeParagraph: " + event.target.value);
    this.setState({ homeParagraph: event.target.value });
  };
  addSliderImage = (index) => {
    console.log("adding slider image to index:" + index);
    this.setState({ addImage: true, destination: index });
  };
  saveHomeParagraph = () => {
    console.log("inside saveHomeParagraph");
    this.setState({ homeParagraphLoading: true });
    this.props.firebase.db
      .collection("site")
      .doc("home")
      .set({ homeParagraph: this.state.homeParagraph }, { merge: true })
      .then(
        function (res) {
          console.log("success saving paragraph");
          this.setState({
            homeParagraphLoading: false,
            homeParagraphMessage: "Successfully Saved Home Paragraph",
          });
          this.getHomeContent();
        }.bind(this)
      )
      .catch(
        function (err) {
          console.log("error saving home paragraph: " + err.message);
          this.setState({
            homeParagraphLoading: false,
            homeParagraphMessage:
              "Error Saving Home Paragraph, Check Connection And Try Again",
          });
        }.bind(this)
      );
  };
  getSiteImages = () => {
    this.props.firebase.db
      .collection("site-images")
      .get()
      .then(
        function (res) {
          let images = [];
          res.forEach((image, i) => {
            console.log("got image");
            images.push(image.data());
          });

          this.setState({ siteImages: images }, () => {
            this.getFeatures();
          });
        }.bind(this)
      )
      .catch(
        function (err) {
          console.log("error getting site images: " + err.message);
          this.getFeatures();
        }.bind(this)
      );
  };
  getFeatures = () => {
    this.props.firebase.db
      .collection("site")
      .doc("home")
      .collection("features")
      .get()
      .then(
        function (res) {
          let features = [];
          res.forEach((feature, i) => {
            features.push(feature.data());
          });
          this.setState({ features: features, loading: false });
        }.bind(this)
      )
      .catch(
        function (err) {
          console.log("error getting features:" + err.message);
          this.setState({
            message:
              "There Was An Error Getting Data From The Database. Refresh And Try Again. ",
            messageType: "danger",
            loading: false,
          });
        }.bind(this)
      );
  };
  saveImageSlider = () => {
    console.log("saving image slider");
    this.setState({ loading: true });
    let slider = {
      image1: this.state.sliderImages[0],
      image2: this.state.sliderImages[1],
      image3: this.state.sliderImages[2],
    };
    this.props.firebase.db
      .collection("site")
      .doc("home")
      .set({ slider: slider }, { merge: true })
      .then(
        function (res) {
          console.log("finished saving image slider");
          this.setState({
            loading: false,
            sliderMessage: "Successfully Saved Image Slider",
          });
        }.bind(this)
      )
      .catch(
        function (err) {
          console.log("error writing slider images");
          this.setState({
            sliderMessage:
              "Error Writing Slider Images, Chech Connection And Try Again",
            loading: false,
          });
        }.bind(this)
      );
  };
  setFeatures = (home) => {
    console.log("setFeatures features");
    if (home.features !== undefined) {
      console.log("features isnt undefined, setting to state");
      this.setState({ features: home.features });
    } else {
      this.setState({ features: [] });
    }
  };
  setHomeParagraph = (home) => {
    if (home.homeParagraph !== undefined) {
      this.setState({ homeParagraph: home.homeParagraph });
    } else {
      this.setState({ homeParagraph: "" });
    }
  };
  setSliderImages = (home) => {
    if (home.slider !== undefined) {
      this.setState(
        {
          sliderImages: [
            home.slider.image1,
            home.slider.image2,
            home.slider.image3,
          ],
        },
        () => {
          this.setHomeParagraph(home);
        }
      );
    }
  };
  getHomeContent = () => {
    this.props.firebase.db
      .collection("site")
      .doc("home")
      .get()
      .then(
        function (res) {
          if (res.exists) {
            this.setSliderImages(res.data());
            this.setState({ home: res.data() });
            this.getSiteImages();
          } else {
            this.getSiteImages();
          }
        }.bind(this)
      )
      .catch(function (err) {
        console.log("error getting home content: " + err.message);
        this.getSiteImages();
      });
  };
  componentDidMount() {
    this.getHomeContent();
  }
  closeAddImage = () => {
    this.setState({ addImage: false });
  };
  addFeature = () => {
    this.setState({ addFeature: true });
  };
  cancelAddFeature = () => {
    this.setState({ addFeature: false });
  };
  finishAddFeature = (feature) => {
    console.log("finishAddFeature");
    if (feature == "success") {
      this.setState(
        {
          message: "Successfully Added Feature",
          messageType: "success",
          addFeature: false,
        },
        () => {
          this.getHomeContent();
        }
      );
    } else {
      this.setState(
        {
          message:
            "There Was An Error Setting The Feature To The Database. Refresh And Try Again.",
          messageType: "danger",
          addFeature: false,
        },
        () => {
          this.getHomeContent();
        }
      );
    }
  };
  closeMessage = () => {
    this.setState({ message: undefined, messageType: "danger" });
  };
  submitEditFeature = (title) => {
    this.setState({ editFeature: false, featureLoading: true });
    this.props.firebase.db
      .collection("site")
      .doc("home")
      .collection("features")
      .doc(this.state.selectedFeature.id)
      .update({ title: title })
      .then(
        function (res) {
          this.setState(
            {
              featureLoading: false,
              message: "Successfully Edited Feature Title",
              messageType: "success",
            },
            () => {
              this.getHomeContent();
            }
          );
        }.bind(this)
      )
      .catch(
        function (err) {
          console.log("error setting feature title : " + err.mesage);
          this.setState(
            {
              message:
                "There Was An Error Editing The Feature Title. Refresh And Try Again",
              messageType: "danger",
            },
            () => {
              this.getHomeContent();
            }
          );
        }.bind(this)
      );
  };
  selectFeature = (feature) => {
    if (this.state.selectedFeature == feature) {
      this.setState({ selectedFeature: undefined });
    } else {
      this.setState({ selectedFeature: feature });
    }
  };
  deleteFeature = () => {
    this.setState({
      confirmation: true,
      confirmationQuestion:
        "Are You Sure You Want To Delete This Feature? This Will Not Affect The Data From Which It Is Derived.",
    });
  };
  deleteSelectedFeature = () => {
    this.props.firebase.db
      .collection("site")
      .doc("home")
      .collection("features")
      .doc(this.state.selectedFeature.id)
      .delete()
      .then(
        function (res) {
          console.log("deleted feature");
          this.setState(
            {
              featureLoading: false,
              message: "Successfully Deleted The Feature.",
              selectedFeature: undefined,
            },
            () => {
              this.getHomeContent();
            }
          );
        }.bind(this)
      )
      .catch(
        function (err) {
          console.log("error deleting feature: " + err.message);
          this.setState(
            {
              message:
                "There Was An Error Deleting The Feature. Refresh And Try Again.",
              selectedFeature: undefined,
              featureLoading: false,
            },
            () => {
              this.getHomeContent();
            }
          );
        }.bind(this)
      );
  };
  answerConfirmDelete = (answer) => {
    if (answer) {
      console.log("will delete feature");
      this.setState({ featureLoading: true, confirmation: false });
      this.deleteSelectedFeature();
    } else {
      this.setState({ confirmation: false });
    }
  };
  editFeature = () => {
    this.setState({ editFeature: true });
  };
  cancelEditFeature = () => {
    this.setState({ editFeature: false });
  };
  render() {
    let message;
    if (this.state.message !== undefined) {
      let messageType;
      if (
        this.state.messageType == undefined ||
        this.state.messageType == null
      ) {
        messageType = "danger";
      } else {
        messageType = this.state.messageType;
      }
      message = (
        <Alert
          closeMessage={this.closeMessage}
          alertType={messageType}
          message={this.state.message}
        />
      );
    } else {
      message = "";
    }
    return (
      <div className="dashboardPanel">
        {this.state.loading ? (
          <i className="spinner fas fa-circle-notch fa-spin" />
        ) : (
          <div>
            <Link className="back" to="/admin-dashboard/pages">
              {"<- Back To Pages"}
            </Link>
            <h1 className="display-5">Home Management</h1>
            {message}
            <div className="dashboardBlock container">
              <p className="display-5">Image Slider</p>{" "}
              {this.state.sliderMessage !== undefined ? (
                <p className="alert alert-primary">
                  {this.state.sliderMessage}
                </p>
              ) : (
                ""
              )}
              <button
                onClick={this.saveImageSlider}
                className="btn btn-sm btn-secondary"
              >
                Save
              </button>
              <div className="row">
                <div className="col col-md-4">
                  <p className="lead">Image 1</p>
                  {this.state.sliderImages[0] !== undefined ? (
                    <img
                      className="dashboardImage"
                      src={this.state.sliderImages[0].url}
                    />
                  ) : (
                    ""
                  )}
                  <button
                    onClick={() => {
                      this.addSliderImage(1);
                    }}
                    className="btn btn-sm btn-secondary"
                  >
                    Add/Change Image
                  </button>
                </div>
                <div className="col col-md-4">
                  {" "}
                  <p className="lead">Image 2</p>
                  {this.state.sliderImages[1] !== undefined ? (
                    <img
                      className="dashboardImage"
                      src={this.state.sliderImages[1].url}
                    />
                  ) : (
                    ""
                  )}
                  <button
                    onClick={() => {
                      this.addSliderImage(2);
                    }}
                    className="btn btn-sm btn-secondary"
                  >
                    Add/Change Image
                  </button>
                </div>
                <div className="col col-md-4">
                  <p className="lead">Image 3</p>
                  {this.state.sliderImages[2] !== undefined ? (
                    <img
                      className="dashboardImage"
                      src={this.state.sliderImages[2].url}
                    />
                  ) : (
                    ""
                  )}
                  <button
                    onClick={() => {
                      this.addSliderImage(3);
                    }}
                    className="btn btn-sm btn-secondary"
                  >
                    Add/Change Image
                  </button>
                </div>
              </div>
            </div>
            <HomeParagraph
              save={this.saveHomeParagraph}
              onChange={this.onHomeParagraphChange}
              paragraph={this.state.homeParagraph}
              message={this.homeParagraphMessage}
              loading={this.state.homeParagraphLoading}
            />
            {message}
            <div className="dashboardBlock container">
              {this.state.featureLoading ? (
                <i className="spinner fas fa-circle-notch fa-spin" />
              ) : (
                <div>
                  <p className="display-5">Features</p>
                  <small>
                    Features are pieces of content for the home page. They can
                    be events, posts, galleries or contact forms. You can add as
                    many as you'd like, but the site works best with 2 - 4
                    features.
                  </small>
                  <button
                    className="btn btn-sm btn-secondary"
                    onClick={this.addFeature}
                  >
                    Add Feature
                  </button>{" "}
                  {this.state.selectedFeature !== undefined ? (
                    <div>
                      <button
                        className="btn btn-sm btn-secondary"
                        onClick={this.editFeature}
                      >
                        Edit Feature
                      </button>
                      <button
                        className="btn btn-sm btn-secondary"
                        onClick={this.deleteFeature}
                      >
                        Delete Feature
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                  <FeatureTable
                    features={this.state.features}
                    selectedFeature={this.state.selectedFeature}
                    selectFeature={this.selectFeature}
                  />
                </div>
              )}
            </div>
            {this.state.addImage ? (
              <div>
                <AddImage
                  siteImages={this.state.siteImages}
                  selectImage={this.setSliderImage}
                />
                <Backdrop click={this.closeAddImage} />
              </div>
            ) : (
              ""
            )}
            {this.state.addFeature ? (
              <div>
                <AddFeature
                  finish={this.finishAddFeature}
                  firebase={this.props.firebase}
                  exit={this.cancelAddFeature}
                  features={this.state.features}
                />
                <Backdrop click={this.cancelAddFeature} />
              </div>
            ) : (
              ""
            )}
            {this.state.editFeature ? (
              <div>
                <EditFeature
                  feature={this.state.selectedFeature}
                  submit={this.submitEditFeature}
                  exit={this.cancelEditFeature}
                />
                <Backdrop click={this.cancelEditFeature} />
              </div>
            ) : (
              ""
            )}
            {this.state.confirmation ? (
              <div>
                <Confirmation
                  question={this.state.confirmationQuestion}
                  answer={this.answerConfirmDelete}
                />
                <Backdrop
                  click={() => {
                    this.answerConfirmDelete(false);
                  }}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(HomeManagement);
