import React, { Component } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export default class AddEvent extends Component {
  constructor() {
    super();
    let date = new Date();
    this.state = {
      eventStartDate: date,
      eventStartDateObject: date,
      eventEndDateObject: date,
      eventEndDate: date,
      eventLocation: { name: "", address: "", city: "", state: "" },
      eventTitle: "",
      eventDescription: "",
      valid: false,
    };
  }
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.validCheck();
    });
  };
  onStartDateSelect = (date) => {
    let dateObj = new Date(date);
    let dateTime = dateObj.getTime();
    let dateObject = { date: dateObj, datetime: dateTime };
    this.setState((prevState) => {
      return { eventStartDate: dateObj, eventStartDateObject: dateObject };
    });
  };
  onEndDateSelect = (date) => {
    let dateObj = new Date(date);
    let dateTime = dateObj.getTime();
    let dateObject = { date: dateObj, datetime: dateTime };
    this.setState((prevState) => {
      return { eventEndDate: dateObj, eventEndDateObject: dateObject };
    });
  };
  validCheck = () => {
    let valid = false;
    if (
      this.state.eventLocation.address !== "" &&
      this.state.eventLocation.name !== "" &&
      this.state.eventTitle !== ""
    ) {
      console.log("valid");
      this.setState({ valid: true });
    } else {
      console.log("not valid");
      this.setState({ valid: false });
    }
  };
  saveEvent = () => {
    if (this.state.valid) {
      let eventObj = {
        start: this.state.eventStartDateObject,
        end: this.state.eventEndDateObject,
        location: this.state.eventLocation,
        name: this.state.eventTitle,
        description: this.state.eventDescription,
      };
      this.props.save(eventObj);
    } else {
      this.setState({
        message:
          "You Must Have An Event Name, Start And End Time, And Location Name To Submit.",
      });
    }
  };
  eventLocationSelect = (event) => {
    console.log("selected event: " + event.target.value);
    this.props.locations.forEach((location, i) => {
      if (location.name == event.target.value) {
        console.log("found location");
        this.setState({ eventLocation: location }, () => {
          this.validCheck();
        });
      }
    });
  };
  onLocationNameChange = (event) => {
    console.log("changing location name");
    let eventLocation = this.state.eventLocation;
    eventLocation.name = event.target.value;
    this.setState({ eventLocation: eventLocation }, () => {
      this.validCheck();
    });
  };
  onLocationAddressChange = (event) => {
    console.log("changing location address");
    let eventLocation = this.state.eventLocation;
    eventLocation.address = event.target.value;
    this.setState({ eventLocation: eventLocation }, () => {
      this.validCheck();
    });
  };
  onLocationCityChange = (event) => {
    console.log("changing location address");
    let eventLocation = this.state.eventLocation;
    eventLocation.city = event.target.value;
    this.setState({ eventLocation: eventLocation }, () => {
      this.validCheck();
    });
  };
  onLocationStateChange = (event) => {
    console.log("changing location address");
    let eventLocation = this.state.eventLocation;
    eventLocation.state = event.target.value;
    this.setState({ eventLocation: eventLocation }, () => {
      this.validCheck();
    });
  };
  render() {
    return (
      <div className="addEvent">
        <h1 className="display-4">Add Event</h1>
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">Event Start Date/Time:</div>
          </div>
          <div className="row">
            <div className="col col-xs-12">
              <DatePicker
                selected={this.state.eventStartDate}
                onChange={this.onStartDateSelect}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="time"
                dateFormat="MMMM d, yyyy h:mm aa"
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">Event End Date/Time:</div>
          </div>
          <div className="row">
            <div className="col col-xs-12">
              <DatePicker
                selected={this.state.eventEndDate}
                onChange={this.onEndDateSelect}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="time"
                dateFormat="MMMM d, yyyy h:mm aa"
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">Event Title:</div>
          </div>
          <div className="row">
            <div className="col col-xs-12">
              <input
                type="text"
                value={this.state.eventTitle}
                onChange={this.onChange}
                name="eventTitle"
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">Event Description:</div>
          </div>
          <div className="row">
            <div className="col col-xs-12">
              <textarea
                value={this.state.eventDescription}
                onChange={this.onChange}
                name="eventDescription"
                className="form-control"
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">Event Location:</div>
          </div>
          <div className="row">
            <div className="col col-xs-12">
              <select
                value={this.state.eventDescription}
                onChange={this.onChange}
                name="eventDescription"
                className="form-control"
                onChange={this.eventLocationSelect}
              >
                <option></option>
                {this.props.locations.map((location, i) => (
                  <option key={i} name={location.name}>
                    {location.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col col-md-6">Location Name:</div>
            <div className="col col-md-6">
              <input
                type="text"
                onChange={this.onLocationNameChange}
                value={this.state.eventLocation.name}
                className="form-control"
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col col-md-6">Location Address:</div>
            <div className="col col-md-6">
              <input
                type="text"
                onChange={this.onLocationAddressChange}
                value={this.state.eventLocation.address}
                className="form-control"
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col col-md-6">Location City:</div>
            <div className="col col-md-6">
              <input
                type="text"
                onChange={this.onLocationCityChange}
                value={this.state.eventLocation.city}
                className="form-control"
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col col-md-6">Location State:</div>
            <div className="col col-md-6">
              <input
                type="text"
                onChange={this.onLocationStateChange}
                value={this.state.eventLocation.state}
                className="form-control"
              />
            </div>
          </div>
          <br />
          <button
            className="btn btn-sm btn-secondary dashboardButton"
            onClick={this.saveEvent}
            disabled={this.state.valid ? false : true}
          >
            Save Event
          </button>
        </div>
      </div>
    );
  }
}
