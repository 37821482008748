import React from "react";
import Feature from "./Feature";

export default function Features(props) {
  return (
    <div className="container-fluid">
      {props.features.map((feature, i) => (
        <div className="row row-space">
          <Feature feature={feature} />
        </div>
      ))}
    </div>
  );
}
