import React, { Component } from "react";
import LoadingScreen from "./../../../common/LoadingScreen";
import NavBar from "./../../nav/NavBar";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";
import { siteName } from "./../../../versionInfo";
import Footer from "./../../Footer";
import "./about.css";
class About extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }
  getAboutContent = () => {
    this.props.firebase.db
      .collection("site")
      .doc("about")
      .get()
      .then(
        function (res) {
          if (res.exists) {
            let about = res.data();
            let aboutImage, aboutParagraph;
            if (about.aboutImage !== undefined) {
              aboutImage = about.aboutImage;
            } else {
              aboutImage = undefined;
            }
            if (about.aboutParagraph !== undefined) {
              aboutParagraph = about.aboutParagraph;
            } else {
              aboutParagraph = "";
            }
            this.setState({
              aboutImage: aboutImage,
              aboutParagraph: aboutParagraph,
            });
          }
        }.bind(this)
      )
      .catch(
        function (err) {
          console.log("error getting about content: " + err.message);
          this.setState({ loading: false });
        }.bind(this)
      );
  };
  componentDidMount() {
    this.getAboutContent();
  }
  render() {
    let styleObj = {
      backgroundColor: this.props.global.colors.backgroundColor,
      height: "100%",
      minHeight: "100%",
    };
    return (
      <div style={styleObj} className="pageContainer">
        {this.state.loading ? (
          <LoadingScreen />
        ) : (
          <div style={styleObj}>
            {" "}
            <NavBar
              nav={this.props.global.nav}
              colors={this.props.global.colors}
              loggedIn={this.props.signedIn}
              admin={this.props.admin}
            />
            <div className="container">
              <div className="row text-center">
                <h1
                  className="display-4 headline"
                  style={{ color: this.props.global.colors.secondaryColor }}
                >
                  About {siteName}
                </h1>
                <div className="col col-md-6">
                  {this.state.aboutImage !== undefined ? (
                    <img
                      className="aboutImage"
                      src={this.state.aboutImage.url}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="col col-md-6">
                  <p className="lead aboutText">{this.state.aboutParagraph}</p>
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    );
  }
}

export default compose(withFirebase)(About);
