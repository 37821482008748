import React, { Component } from "react";
import LoginForm from "./LoginForm";
import { withFirebase } from "./../../firebase/index";
import { compose } from "redux";
import { admin } from "./../../versionInfo";
import NavBar from "../nav/NavBar";
import Footer from "../Footer";

class Login extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    console.log(
      "inside login componenet, auth is : " +
        JSON.stringify(this.props.firebase.auth)
    );
    // if (this.props.firebase.auth.uid) {
    //   console.log("have uid");
    // } else if (this.props.firebase.auth.currentUser) {
    //   console.log("have uid");
    // }
    this.props.firebase.auth.onAuthStateChanged(
      function (user) {
        if (user) {
          console.log("user signed in, should forward");
          if (this.props.firebase.auth.currentUser.email == admin) {
            console.log("admin");
            this.props.history.push("/admin-dashboard");
          } else {
            this.props.history.push("/dashboard");
          }
        } else {
          console.log("user signed out");
        }
      }.bind(this)
    );
  }
  render() {
    return (
      <div className="pageContainer">
        <NavBar
          nav={this.props.global.nav}
          colors={this.props.global.colors}
          loggedIn={this.props.signedIn}
          admin={this.props.admin}
        />
        <LoginForm fire={this.props.firebase} />
        <Footer />
      </div>
    );
  }
}

export default compose(withFirebase)(Login);
