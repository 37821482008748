import React, { Component } from "react";

export default class AddEventLocation extends Component {
  constructor() {
    super();
    this.state = {};
  }
  addLocation = () => {
    let location = {};
    let locationName, locationAddress, locationCity;
    if (this.state.locationName !== undefined) {
      locationName = this.state.locationName;
    } else {
      locationName = "";
    }
    if (this.state.locationAddress !== undefined) {
      locationAddress = this.state.locationAddress;
    } else {
      locationAddress = "";
    }
    if (this.state.locationCity !== undefined) {
      locationCity = this.state.locationCity;
    } else {
      locationCity = "";
    }
    location = {
      name: locationName,
      address: locationAddress,
      city: locationCity
    };
    console.log("adding location: " + JSON.stringify(location));
    this.props.add(location);
  };
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    return (
      <div className="addEvent">
        <h1 className="display-4">Add Event Location</h1>
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">Location Name</div>
          </div>
          <div className="row">
            <div className="col col-xs-12">
              <input
                type="text"
                className="form-control"
                name="locationName"
                value={this.state.locationName}
                onChange={this.onChange}
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">Location Address</div>
          </div>
          <div className="row">
            <div className="col col-xs-12">
              <input
                type="text"
                className="form-control"
                name="locationAddress"
                value={this.state.locationAddress}
                onChange={this.onChange}
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">Location City</div>
          </div>
          <div className="row">
            <div className="col col-xs-12">
              <input
                type="text"
                className="form-control"
                name="locationCity"
                value={this.state.locationCity}
                onChange={this.onChange}
              />
            </div>
          </div>
        </div>
        <button className="btn btn-sm btn-secondary" onClick={this.addLocation}>
          Add Event Location
        </button>
      </div>
    );
  }
}
