import React, { Component } from "react";
import AddEvent from "./AddEvent";
import EditEvent from "./EditEvent";
import AddEventLocation from "./AddEventLocation";
import Backdrop from "./../../../../common/Backdrop";
import { withFirebase } from "./../../../../firebase/index";
import { compose } from "redux";
import "./events.css";
import EventsLocationTable from "./EventsLocationTable";
import EventsTable from "./EventsTable";
import Confirmation from "./../../../../common/Confirmation";
import Alert from "./../../../../common/Alert";
import LoadingScreen from "./../../../../common/LoadingScreen";

class EventsManagement extends Component {
  constructor() {
    super();
    this.state = {
      addEvent: false,
      addEventLocation: false,
      valid: true,
      loading: true,
      selectedEvent: undefined,
      eventDeleteConfirmation: false,
      locationDeleteConfirmation: false,
      eventsMessage: undefined,
      eventLocationMessage: undefined,
    };
  }
  addEvent = () => {
    this.setState({ addEvent: true });
  };
  saveEditedEvent = (eventObj) => {
    console.log("saving event: " + JSON.stringify(eventObj));
    this.setState({ eventsLoading: true, editEvent: false });
    let ref = this.props.firebase.db
      .collection("site")
      .doc("events")
      .collection("events")
      .doc(eventObj.id);
    let id = eventObj.id;
    ref
      .set(eventObj)
      .then(
        function (res) {
          console.log("success setting event");
          this.setState(
            {
              eventsLoading: false,
              eventsMessage: "Successfully Edited Event",
              selectedEvent: undefined,
              messageType: "success",
            },
            () => {}
          );
        }.bind(this)
      )
      .catch(function (err) {
        console.log("error setting event info: " + err.message);
        this.setState({
          eventsLoading: false,
          eventsMessage: "Error Editing Event. Refresh And Try Again.",
          messageType: "danger",
        });
      });
  };
  saveEvent = (eventObj) => {
    console.log("saving event: " + JSON.stringify(eventObj));
    this.setState({ eventsLoading: true, addEvent: false });
    let ref = this.props.firebase.db
      .collection("site")
      .doc("events")
      .collection("events")
      .doc();
    let id = ref.id;
    eventObj.id = id;
    ref
      .set(eventObj)
      .then(
        function (res) {
          console.log("success setting event");
          this.setState(
            {
              eventsLoading: false,
              eventsMessage: "Successfully Added Event",
              selectedEvent: undefined,
              messageType: "success",
            },
            () => {}
          );
        }.bind(this)
      )
      .catch(function (err) {
        console.log("error setting event info: " + err.message);
        this.setState({
          eventsLoading: false,
          eventsMessage: "Error Adding Event. Refresh And Try Again.",
          messageType: "danger",
        });
      });
  };
  addEventLocation = () => {
    this.setState({ addEventLocation: true });
  };
  saveEventLocation = (location) => {
    this.setState({ eventLocationLoading: true, addEventLocation: false });
    let ref = this.props.firebase.db
      .collection("site")
      .doc("events")
      .collection("event-locations")
      .doc();
    let id = ref.id;
    location.id = id;
    ref
      .set(location)
      .then(
        function (res) {
          console.log("success setting location");
          this.setState(
            {
              eventLocationLoading: false,
              eventLocationMessage: "Successfully Added Event Location",
              selectedLocation: undefined,
            },
            () => {
              this.getEventLocations();
            }
          );
        }.bind(this)
      )
      .catch(function (err) {
        console.log("error setting location info: " + err.message);
      });
  };
  closeAddEventLocation = () => {
    this.setState({ addEventLocation: false });
  };
  closeAddEvent = () => {
    this.setState({ addEvent: false });
  };
  selectLocation = (locationObj) => {
    let selectedLocation = undefined;
    this.state.eventLocations.forEach((location, i) => {
      if (location.name == locationObj.name) {
        if (location.selected) {
          location.selected = false;
          selectedLocation = undefined;
        } else {
          location.selected = true;
          selectedLocation = location;
        }
      } else {
        location.selected = false;
      }
    });

    this.setState({ selectedLocation: selectedLocation });
  };
  deleteLocation = () => {
    this.setState({ locationDeleteConfirmation: true });
  };
  deleteSelectedLocation = () => {
    this.setState({ eventLocationLoading: true });
    let newLocations = [];
    let idToDelete;
    this.state.eventLocations.forEach((location, i) => {
      if (!location.selected) {
        newLocations.push(location);
      } else {
        idToDelete = location.id;
      }
    });
    this.props.firebase.db
      .collection("site")
      .doc("events")
      .collection("event-locations")
      .doc(idToDelete)
      .delete()
      .then(
        function (res) {
          console.log("deleted location");
          this.setState(
            {
              eventLocationLoading: false,
              eventLocations: newLocations,
              selectedLocation: undefined,
              eventLocationMessage: "Successfully Deleted Event Location",
              messageType: "success",
            },
            () => {
              this.getEventLocations();
            }
          );
        }.bind(this)
      )
      .catch(
        function (err) {
          console.log("error deleting location: " + err.message);
          this.setState({
            eventLocationLoading: false,
            eventLocationMessage:
              "Error Deleting Event Location. Refresh And Try Again.",
            messageType: "danger",
          });
        }.bind(this)
      );
  };
  answerLocationDeleteConfirmation = (answer) => {
    this.setState({ locationDeleteConfirmation: false });
    if (answer) {
      this.deleteSelectedLocation();
    } else {
      return;
    }
  };
  closeLocationDeleteConfirmation = () => {
    this.setState({ locationDeleteConfirmation: false });
  };
  getEventLocations = () => {
    this.props.firebase.db
      .collection("site")
      .doc("events")
      .collection("event-locations")
      .get()
      .then(
        function (res) {
          let locations = [];
          res.forEach((location) => {
            console.log("got location");
            let locationObj = location.data();
            locationObj.selected = false;
            locations.push(locationObj);
          });
          this.setState({ eventLocations: locations, loading: false });
        }.bind(this)
      )
      .catch(
        function (err) {
          console.log("error getting event locations: " + err.message);
          this.setState({ loading: false });
        }.bind(this)
      );
  };
  getEvents = () => {
    this.props.firebase.db
      .collection("site")
      .doc("events")
      .collection("events")
      .get()
      .then(
        function (res) {
          let events = [];
          res.forEach((event, i) => {
            console.log("got event");
            events.push(event.data());
          });
          this.setState({ events: events });
        }.bind(this)
      )
      .catch(function (err) {
        console.log("error getting events: " + err.message);
      });
  };
  deleteEvent = () => {
    console.log("delete event clicked");
    this.setState({ eventDeleteConfirmation: true });
  };
  answerEventDelete = (answer) => {
    if (answer) {
      console.log("will delete");
      this.setState({ eventDeleteConfirmation: false });
      this.deleteSelectedEvent();
    } else {
      console.log("wont delete");
      this.setState({ eventDeleteConfirmation: false });
    }
  };
  closeEventDeleteConfirmation = () => {
    this.setState({ eventDeleteConfirmation: false });
  };
  deleteSelectedEvent = () => {
    this.setState({ eventLoading: true });
    let newEvents = [];
    let idToDelete;
    this.state.events.forEach((event, i) => {
      if (!event.selected) {
        newEvents.push(event);
      } else {
        idToDelete = event.id;
      }
    });
    this.props.firebase.db
      .collection("site")
      .doc("events")
      .collection("events")
      .doc(idToDelete)
      .delete()
      .then(
        function (res) {
          console.log("deleted event");
          this.setState(
            {
              eventLoading: false,
              events: newEvents,
              selectedEvent: undefined,
              eventsMessage: "Successfully Deleted Event",
              messageType: "success",
            },
            () => {
              this.getEvents();
            }
          );
        }.bind(this)
      )
      .catch(
        function (err) {
          console.log("error deleting event: " + err.message);
          this.setState({
            eventLoading: false,
            eventsMessage:
              "There Was An Error Trying To Delete The Event. Refresh And Try Again.",
            messageType: "danger",
          });
        }.bind(this)
      );
  };
  selectEvent = (eventObj) => {
    let newStateEvents = [...this.state.events];
    let selectedEvent = undefined;
    this.state.events.forEach((eventStateObj, i) => {
      if (eventObj === eventStateObj) {
        console.log("got event");
        if (newStateEvents[i].selected == true) {
          newStateEvents[i].selected = false;
        } else {
          newStateEvents[i].selected = true;
          selectedEvent = newStateEvents[i];
        }
      } else {
        newStateEvents[i].selected = false;
      }
    });

    this.setState({ events: newStateEvents, selectedEvent: selectedEvent });
  };
  editEvent = () => {
    this.setState({ editEvent: true });
  };
  closeEventsMessage = () => {
    console.log("closing eventsMessage");
    this.setState({ eventsMessage: undefined, messageType: "danger" });
  };
  closeEventLocationMessage = () => {
    this.setState({ eventLocationMessage: undefined, messageType: "danger" });
  };
  componentDidMount() {
    this.getEvents();
    this.getEventLocations();
  }
  render() {
    let eventsMessage, eventLocationMessage;
    if (this.state.eventsMessage !== undefined) {
      let messageType;
      if (
        this.state.messageType == undefined ||
        this.state.messageType == null
      ) {
        messageType = "danger";
      } else {
        messageType = this.state.messageType;
      }
      eventsMessage = (
        <Alert
          closeMessage={this.closeEventsMessage}
          alertType={messageType}
          message={this.state.eventsMessage}
        />
      );
    } else {
      eventsMessage = "";
    }
    if (this.state.eventLocationMessage !== undefined) {
      let messageType;
      if (
        this.state.messageType == undefined ||
        this.state.messageType == null
      ) {
        messageType = "danger";
      } else {
        messageType = this.state.messageType;
      }
      eventLocationMessage = (
        <Alert
          closeMessage={this.closeEventLocationMessage}
          alertType={messageType}
          message={this.state.eventLocationMessage}
        />
      );
    } else {
      eventLocationMessage = "";
    }
    return (
      <div className="dashboardPanel">
        {this.state.loading ? (
          <LoadingScreen />
        ) : (
          <div>
            <h1 className="display-5">Events Management</h1>
            {this.state.eventsLoading ? (
              <LoadingScreen />
            ) : (
              <div className="dashboardBlock container">
                <p className="display-5">Club Events</p> {eventsMessage}
                <button
                  onClick={this.addEvent}
                  className="btn btn-sm btn-secondary dashboardButton"
                >
                  Add Event
                </button>
                {this.state.selectedEvent !== undefined ? (
                  <div>
                    <button
                      className="btn btn-sm btn-secondary dashboardButton"
                      onClick={this.editEvent}
                    >
                      Edit Event
                    </button>
                    <button
                      className="btn btn-sm btn-secondary dashboardButton"
                      onClick={this.deleteEvent}
                    >
                      Delete Event
                    </button>
                  </div>
                ) : (
                  ""
                )}
                <EventsTable
                  events={this.state.events}
                  selectEvent={this.selectEvent}
                />
              </div>
            )}

            {this.state.eventLocationLoading ? (
              <LoadingScreen />
            ) : (
              <div className="dashboardBlock container">
                <p className="display-5">Event Locations</p>{" "}
                {eventLocationMessage}
                <button
                  onClick={this.addEventLocation}
                  className="btn btn-sm btn-secondary dashboardButton"
                >
                  Add Event Location
                </button>
                {this.state.selectedLocation !== undefined ? (
                  <div>
                    <button
                      className="btn btn-sm btn-secondary dashboardButton"
                      onClick={this.deleteLocation}
                    >
                      Delete Location
                    </button>
                  </div>
                ) : (
                  ""
                )}
                <EventsLocationTable
                  locations={this.state.eventLocations}
                  selectLocation={this.selectLocation}
                />
              </div>
            )}
            {this.state.locationDeleteConfirmation ? (
              <div>
                <Confirmation
                  question="Are You Sure You Want To Delete This Event Location?"
                  answer={this.answerLocationDeleteConfirmation}
                />
                <Backdrop click={this.closeLocationDeleteConfirmation} />
              </div>
            ) : (
              ""
            )}
            {this.state.eventDeleteConfirmation ? (
              <div>
                <Confirmation
                  question="Are You Sure You Want To Delete This Event?"
                  answer={this.answerEventDelete}
                />
                <Backdrop click={this.closeEventDeleteConfirmation} />
              </div>
            ) : (
              ""
            )}
            {this.state.addEventLocation ? (
              <div>
                <AddEventLocation
                  locations={this.state.eventLocations}
                  loading={this.eventLocationLoading}
                  message={this.eventLocationMessage}
                  add={this.saveEventLocation}
                />
                <Backdrop click={this.closeAddEventLocation} />
              </div>
            ) : (
              ""
            )}
            {this.state.addEvent ? (
              <div>
                <AddEvent
                  siteEvents={this.state.siteEvents}
                  locations={this.state.eventLocations}
                  save={this.saveEvent}
                />
                <Backdrop click={this.closeAddEvent} />
              </div>
            ) : (
              ""
            )}
            {this.state.editEvent ? (
              <div>
                <EditEvent
                  siteEvents={this.state.siteEvents}
                  event={this.state.selectedEvent}
                  locations={this.state.eventLocations}
                  save={this.saveEditedEvent}
                />
                <Backdrop click={this.closeAddEvent} />
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(EventsManagement);
