import React from "react";
import formatDate from "./../../../../common/formatDate";

export default function EventsLocationTable(props) {
  return (
    <div className="clientsTableContainer">
      Events
      <table className="table table-hover">
        <thead className="thead-dark">
          <tr>
            <th></th>
            <th className="sort" scope="col">
              Date
            </th>
            <th className="sort" scope="col">
              Name
            </th>
            <th className="sort" scope="col">
              Location
            </th>
          </tr>
        </thead>
        <tbody>
          {props.events.map((eventObj, i) => (
            <tr key={i}>
              <td>
                <input
                  type="checkbox"
                  checked={eventObj.selected ? true : false}
                  id="selectEvent"
                  onClick={() => {
                    props.selectEvent(eventObj);
                  }}
                ></input>
              </td>
              <th scope="row">{formatDate(eventObj.start.datetime)}</th>
              <th scope="row">{eventObj.name}</th>
              <th scope="row">{eventObj.location.name}</th>
              <th scope="row"></th>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
