import React, { Component } from "react";
import { withFirebase } from "./../../../../firebase/index";
import { compose } from "redux";
import LoadingScreen from "./../../../../common/LoadingScreen";
import Backdrop from "./../../../../common/Backdrop";
import Alert from "./../../../../common/Alert";
import Confirmation from "./../../../../common/Confirmation";
import AddImage from "./../../../../common/AddImage";
import GalleryManagementPanel from "./GalleryManagementPanel";
import "./galleryManagement.css";

class GalleryManagement extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      gallery: [],
      message: undefined,
      messageType: "danger",
      selectedImage: { url: "" },
      dbGallery: [],
      galleryType: "grid",
    };
  }
  changeGalleryType = (type) => {
    this.setState({ galleryType: type });
  };
  getGallery = () => {
    this.props.firebase.db
      .collection("site")
      .doc("gallery")
      .get()
      .then(
        function (res) {
          if (res.exists) {
            console.log("res exists, setting gallery to state");
            let galleryObject = res.data();
            if (galleryObject.images == undefined) {
              galleryObject.images = [];
            }
            this.setState(
              {
                gallery: galleryObject.images,
                dbGallery: galleryObject.images,
                galleryType: galleryObject.type,
              },
              () => {
                this.getSiteImages();
              }
            );
          } else {
            this.setState({ gallery: { images: [] } }, () => {
              this.getSiteImages();
            });
          }
        }.bind(this)
      )
      .catch(function (err) {
        console.log("error getting gallery images: " + err.message);
      });
  };
  getSiteImages = () => {
    this.props.firebase.db
      .collection("site-images")
      .get()
      .then(
        function (res) {
          let images = [];
          res.forEach((image, i) => {
            console.log("got image");
            images.push(image.data());
          });

          this.setState({ siteImages: images, loading: false }, () => {
            this.checkGallery();
          });
        }.bind(this)
      )
      .catch(
        function (err) {
          console.log("error getting site images: " + err.message);
          this.setState({ loading: false });
        }.bind(this)
      );
  };
  checkGallery = () => {
    let valid = true;
    this.state.gallery.forEach((img, i) => {
      if (img.thumbFive == undefined) {
        console.log("no thumb, invalid: " + JSON.stringify(img));
        valid = false;
      }
    });
    this.setState({ valid: valid });
  };
  addToGallery = (image) => {
    console.log("adding to gallery: " + JSON.stringify(image));
    if (image.thumbFive == undefined) {
      this.setState({
        message:
          "The Image You Tried To Add Did Not Have A Thumbnail. Go To The Images Component And Generate A Thumbnail For That Image And Then Come Back And Try Again.",
        messageType: "danger",
      });
    } else {
      let gallery = [...this.state.gallery];
      gallery.push(image);
      this.setState({ gallery: gallery, addImage: false });
    }
  };
  selectImage = (image) => {
    if (image.url == this.state.selectedImage.url) {
      this.setState({ selectedImage: { url: "" } });
    } else {
      this.setState({ selectedImage: image });
    }
  };
  componentDidMount() {
    this.getGallery();
  }
  addImage = () => {
    this.setState({ addImage: true });
  };
  cancelAddImage = () => {
    this.setState({ addImage: false });
  };
  saveGallery = () => {
    let gallery = { images: this.state.gallery, type: this.state.galleryType };
    this.props.firebase.db
      .collection("site")
      .doc("gallery")
      .set(gallery)
      .then(
        function (res) {
          this.setState(
            { message: "Successfully Saved Gallery", messageType: "success" },
            () => {
              this.getGallery();
            }
          );
        }.bind(this)
      )
      .catch(
        function (err) {
          console.log("error saving gallery document: " + err.message);
          this.setState({
            message:
              "There Was An Error Saving The Gallery Document. Refresh And Try Again.",
            messageType: "danger",
          });
        }.bind(this)
      );
  };
  closeMessage = () => {
    this.setState({ message: undefined, messageType: "danger" });
  };
  removeImage = () => {
    let gallery = [...this.state.gallery];
    let newGallery = [];
    gallery.forEach((img, i) => {
      if (img.url !== this.state.selectedImage.url) {
        newGallery.push(img);
      }
    });
    this.setState({ gallery: newGallery });
  };
  generateFiveThumb = (fivePath) => {
    console.log("inside generate fiveThumb: fivePath is: " + fivePath);
    return new Promise(
      function (promiseRes, rej) {
        this.props.firebase.storage
          .ref(fivePath)
          .getDownloadURL()
          .then(
            function (url) {
              let imageObj = this.state.selectedImage;
              imageObj.thumbTwo = url;
              this.props.firebase.db
                .collection("site-images")
                .doc(this.state.selectedImage.id)
                .update({ thumbFive: url })
                .then(function (res) {
                  console.log("successfully wrote fiveThumb to database");
                  promiseRes(url);
                })
                .catch(function (err) {
                  console.log("error writing five thumb to database");
                  rej(err);
                });
            }.bind(this)
          )
          .catch(
            function (err) {
              console.log(
                "error getting download url for 500 thumb: " + err.messages
              );
              this.setState({
                error: true,
                loading: false,
                message: "Error Generating 500 pixel wide Thumb",
              });
              rej(err);
            }.bind(this)
          );
      }.bind(this)
    );
  };

  generateThumbnails = () => {
    console.log(
      "inside generate thumbnails, path is : " + this.state.selectedImage.path
    );

    let strings = this.state.selectedImage.path.split("/");
    let lastPart = this.state.selectedImage.path.split("/").pop();
    let twoLastPart = "thumb@200_" + lastPart;
    let fiveLastPart = "thumb@500_" + lastPart;
    let twoPath = strings[0] + "/" + strings[1] + "/" + twoLastPart;
    console.log("twoPath is: " + twoPath);
    let fivePath = strings[0] + "/" + strings[1] + "/" + fiveLastPart;

    this.generateFiveThumb(fivePath)
      .then(
        function (res) {
          this.setState(
            { message: "thumbs generated successfully", loading: false },
            () => {
              console.log(
                "success generating thumbnails, check to see if it refreshed"
              );
              this.getSiteImages();
            }
          );
        }.bind(this)
      )
      .catch(function (err) {
        console.log("error generating fiveThumb");
      });
  };
  render() {
    let message, confirmation;
    if (this.state.message !== undefined) {
      let messageType;
      if (
        this.state.messageType == undefined ||
        this.state.messageType == null
      ) {
        messageType = "danger";
      } else {
        messageType = this.state.messageType;
      }
      message = (
        <Alert
          closeMessage={this.closeMessage}
          alertType={messageType}
          message={this.state.message}
        />
      );
    } else {
      message = "";
    }
    if (this.state.confirmation) {
      confirmation = (
        <Confirmation
          question={this.state.confirmationQuestion}
          answer={this.answerConfirmDelete}
        />
      );
    } else {
      confirmation = "";
    }
    return (
      <div className="dashboardPanel">
        {this.state.loading ? (
          <LoadingScreen />
        ) : (
          <div>
            <h1>Gallery Management</h1>
            {message}

            <br />
            <hr />
            <div className="dashboardControls">
              <button
                onClick={this.addImage}
                className="btn btn-sm btn-secondary dashboardButton"
              >
                Add Gallery Images
              </button>
              {this.state.selectedImage.url !== "" ? (
                <div>
                  <button
                    className="btn  btn-sm btn-secondary"
                    onClick={this.removeImage}
                  >
                    Remove Selected Image
                  </button>
                  <button
                    onClick={this.generateThumbnails}
                    className="dashboardButton btn btn-sm btn-secondary"
                    disabled={
                      this.state.selectedImage.thumbFive !== undefined
                        ? true
                        : false
                    }
                  >
                    Generate Thumbnails
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
            {this.state.gallery !== this.state.dbGallery
              ? "Not Saved"
              : "Saved"}
            {this.state.valid ? (
              <p>All Images Have Thumbnails. This Gallery Should Work.</p>
            ) : (
              <p>
                Some Images Don't Have Thumbnails. This Gallery Won't Work As
                Is. Click On Each Image, If No Thumbnail Exists, You Will Be
                Able To Click The "Generate Thumbnails" Button.
              </p>
            )}

            <br />
            <p className="lead">Gallery Type:</p>
            <div className="row form-group text-center">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="grid"
                  id="grid1"
                  onChange={() => {
                    this.changeGalleryType("grid");
                  }}
                  checked={this.state.galleryType == "grid" ? true : false}
                />
                <label className="form-check-label" htmlFor="grid">
                  Grid
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="cinema"
                  id="cinema"
                  onChange={() => {
                    this.changeGalleryType("cinema");
                  }}
                  checked={this.state.galleryType == "cinema" ? true : false}
                />
                <label className="form-check-label" htmlFors="cinema">
                  Cinema
                </label>
              </div>
            </div>
            <GalleryManagementPanel
              gallery={this.state.gallery}
              selectImage={this.selectImage}
              delete={this.deleteImage}
              selectedImage={this.state.selectedImage}
            />
            <button
              className="btn btn-sm btn-primary"
              onClick={this.saveGallery}
            >
              Save Gallery
            </button>
          </div>
        )}
        {this.state.addImage ? (
          <div>
            <AddImage
              siteImages={this.state.siteImages}
              selectImage={this.addToGallery}
            />
            <Backdrop click={this.cancelAddImage} />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(GalleryManagement);
