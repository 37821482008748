import React from "react";
import { Slide } from "react-slideshow-image";
import "./slider.css";

export default function Slider(props) {
  const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    arrows: true,
    pauseOnHover: true,
    onChange: (oldIndex, newIndex) => {
      console.log(`slide transition from ${oldIndex} to ${newIndex}`);
    },
  };

  return (
    <div className="slide-container">
      <Slide {...properties}>
        <div className="each-slide">
          <div style={{ backgroundImage: `url(${props.slider[0].src})` }}></div>
        </div>
        <div className="each-slide">
          <div style={{ backgroundImage: `url(${props.slider[1].src})` }}></div>
        </div>
        <div className="each-slide">
          <div style={{ backgroundImage: `url(${props.slider[2].src})` }}></div>
        </div>
      </Slide>
    </div>
  );
}
