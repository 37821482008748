import React, { Component } from "react";
import NavBar from "./../nav/NavBar";
import LoadingScreen from "./../../common/LoadingScreen";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import NewUser from "./NewUser";

export default class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      loading: false
    };
  }
  render() {
    return (
      <div
        style={{
          backgroundColor: this.props.global.colors.backgroundColor
        }}
      >
        {this.state.loading ? (
          <LoadingScreen />
        ) : (
          <div
            style={{
              backgroundColor: this.props.global.colors.backgroundColor
            }}
          >
            {" "}
            <NavBar
              nav={this.props.global.nav}
              colors={this.props.global.colors}
              loggedIn={true}
              admin={this.props.admin}
            />
            <div>
              <Route exact path="/dashboard/new-user" component={NewUser} />
            </div>
          </div>
        )}
      </div>
    );
  }
}
