import React from "react";

export default function GetParagraph(props) {
  return (
    <div>
      <textarea
        className="paragraphInput"
        value={props.value}
        onChange={props.onChange}
      />
    </div>
  );
}
