import React from "react";
import formatDate from "../../../common/formatDate";
import moment from "moment";

export default function EventView(props) {
  let startDateString, endDateString;
  startDateString = formatDate(moment(props.event.starttime).format());
  endDateString = formatDate(moment(props.event.endtime).format());
  return (
    <div className="eventView">
      <h1 className="display-4">{props.event.title}</h1>
      <p className="lead">Start:{startDateString}</p>
      <p className="lead">End:{endDateString}</p>
      <p className="lead">Description:{props.event.description}</p>
      <p className="lead">
        Location: {props.event.location.address} City:{" "}
        {props.event.location.city}
      </p>
    </div>
  );
}
