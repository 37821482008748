import React, { Component } from "react";
import logo from "./logo.svg";
import Landing from "./components/Landing/Landing";
import Contact from "./components/PublicPages/Contact/Contact";
import Login from "./components/Login/Login";
import Register from "./components/Login/Register";
import LoadingScreen from "./common/LoadingScreen";
import "./App.css";
import PrivateAdminRoute from "./common/PrivateAdminRoute";
import PrivateRoute from "./common/PrivateRoute";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { withFirebase } from "./firebase/index";
import { compose } from "redux";
import "./styles/animate.css";
import "./styles/fontAwesome.css";
import Dashboard from "./components/Dashboard/Dashboard";
import AdminDashboard from "./components/AdminDashboard/AdminDashboard";
import About from "./components/PublicPages/About/About";
import { admin } from "./versionInfo";
import News from "./components/PublicPages/News/News";
import Events from "./components/PublicPages/Events/Events";
import GalleryPage from "./components/PublicPages/Gallery/GalleryPage";

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
    };
  }
  componentDidMount() {
    console.log("from appJS, pathname is : " + window.location.pathname);
    this.setupAuthListener();
  }
  getGlobalSettings = () => {
    console.log("getting global ");
    this.props.firebase.db
      .collection("site")
      .doc("nav")
      .get()
      .then(
        function (res) {
          if (res.exists) {
            console.log("got nav data");
            let nav = res.data();
            this.setState({ nav: nav }, () => {
              this.props.firebase.db
                .collection("site")
                .doc("colors")
                .get()
                .then(
                  function (res) {
                    if (res.exists) {
                      console.log("got colors, setting global");
                      let colors = res.data();
                      let global = { colors: colors, nav: this.state.nav };
                      this.setState({
                        colors: colors,
                        global: global,
                        loading: false,
                      });
                    } else {
                      //no color settings
                      console.log("no colors");
                      let colors = {
                        primaryColor: "#ffffff",
                        secondaryColor: "#000000",
                        buttonColor: "#6c757d",
                        backgroundColor: "#ffffff",
                      };
                      this.setState({
                        global: { colors: colors, nav: this.state.nav },

                        loading: false,
                      });
                    }
                  }.bind(this)
                )
                .catch(function (err) {
                  console.log("error getting site colors: " + err.message);
                });
            });
          } else {
            //no nav settings
            console.log("no nav settings");
            let nav = { logoPlacement: "left", style: "relative" };
            this.setState({ nav: nav }, () => {
              this.props.firebase.db
                .collection("site")
                .doc("colors")
                .get()
                .then(
                  function (res) {
                    if (res.exists) {
                      let colors = res.data();
                      let global = { colors: colors, nav: this.state.nav };
                      this.setState({
                        colors: colors,
                        global: global,
                        loading: false,
                      });
                    } else {
                      //no color settings
                      console.log("no nav, no colors");
                      let colors = {
                        primaryColor: "#ffffff",
                        secondaryColor: "#000000",
                        buttonColor: "#6c757d",
                        backgroundColor: "#ffffff",
                      };
                      this.setState({
                        global: {
                          colors: colors,
                          nav: this.state.nav,
                        },
                        colors: colors,
                        loading: false,
                      });
                    }
                  }.bind(this)
                )
                .catch(function (err) {
                  console.log("error getting site colors: " + err.message);
                });
            });
          }
        }.bind(this)
      )
      .catch(function (err) {
        console.log("error getting nav stuff");
      });
  };
  setupAuthListener = () => {
    this.props.firebase.auth.onAuthStateChanged(
      function (user) {
        if (user) {
          console.log("user signed in, should forward");
          if (user.email == admin[0] || user.email == admin[1]) {
            this.setState({ signedIn: true, admin: true }, () => {
              this.getGlobalSettings();
            });
          } else {
            this.setState({ signedIn: true, admin: false }, () => {
              this.getGlobalSettings();
            });
          }
        } else {
          console.log("user signed out");
          this.setState({ signedIn: false }, () => {
            this.getGlobalSettings();
          });
        }
      }.bind(this)
    );
  };
  render() {
    return (
      <div
        className="appContainer d-flex flex-column"
        style={
          this.state.loading
            ? {}
            : { backgroundColor: this.state.colors.backgroundColor }
        }
      >
        {this.state.loading ? (
          <LoadingScreen />
        ) : (
          <Router>
            <Route
              exact
              path="/"
              render={(props) => (
                <Landing
                  {...props}
                  global={this.state.global}
                  signedIn={this.state.signedIn}
                  admin={this.state.admin}
                />
              )}
            />
            <Route
              exact
              path="/login"
              render={(props) => (
                <Login
                  {...props}
                  global={this.state.global}
                  signedIn={this.state.signedIn}
                  admin={this.state.admin}
                />
              )}
            />
            <Route
              exact
              path="/register"
              render={(props) => (
                <Register
                  {...props}
                  global={this.state.global}
                  signedIn={this.state.signedIn}
                  admin={this.state.admin}
                />
              )}
            />
            <Route
              exact
              path="/about"
              render={(props) => (
                <About
                  {...props}
                  global={this.state.global}
                  signedIn={this.state.signedIn}
                  admin={this.state.admin}
                />
              )}
            />
            <Route
              exact
              path="/contact"
              render={(props) => (
                <Contact
                  {...props}
                  global={this.state.global}
                  signedIn={this.state.signedIn}
                  admin={this.state.admin}
                />
              )}
            />
            <Route
              exact
              path="/news"
              render={(props) => (
                <News
                  {...props}
                  global={this.state.global}
                  signedIn={this.state.signedIn}
                  admin={this.state.admin}
                />
              )}
            />
            <Route
              exact
              path="/events"
              render={(props) => (
                <Events
                  {...props}
                  global={this.state.global}
                  signedIn={this.state.signedIn}
                  admin={this.state.admin}
                />
              )}
            />
            <Route
              exact
              path="/gallery"
              render={(props) => (
                <GalleryPage
                  {...props}
                  global={this.state.global}
                  signedIn={this.state.signedIn}
                  admin={this.state.admin}
                />
              )}
            />
            <Switch>
              <PrivateAdminRoute
                signedIn={this.state.signedIn}
                global={this.state.global}
                admin={this.state.admin}
                loading={this.state.loading}
                path="/admin-dashboard"
                component={AdminDashboard}
              />
            </Switch>
            <Switch>
              <PrivateRoute
                path="/dashboard"
                global={this.state.global}
                firebase={this.props.firebase}
                component={Dashboard}
                signedIn={this.state.signedIn}
                admin={this.state.admin}
                loading={this.state.loading}
              />
            </Switch>
          </Router>
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(App);
