import React, { Component } from "react";
import LoadingScreen from "../../../../../common/LoadingScreen";
import PostsTable from "./../../News/PostsTable";

export default class AddFeaturePost extends Component {
  constructor() {
    super();
    this.state = { posts: [], loading: true, selectedPost: undefined };
  }
  selectPost = (post) => {
    let posts = [...this.state.posts];
    let selectedPost = undefined;
    posts.forEach((postObj, i) => {
      if (post == postObj) {
        if (postObj.selected) {
          postObj.selected = false;
        } else {
          postObj.selected = true;
          selectedPost = postObj;
        }
      } else {
        postObj.selected = false;
      }
    });
    this.setState({ posts: posts, selectedPost: selectedPost });
  };
  getPosts() {
    this.props.firebase.db
      .collection("site")
      .doc("news")
      .collection("posts")
      .get()
      .then(
        function (res) {
          let posts = [];
          res.forEach((postObj, i) => {
            posts.push(postObj.data());
          });
          this.setState({ posts: posts, loading: false });
        }.bind(this)
      )
      .catch(function (err) {
        console.log("error getting posts: " + err.message);
      });
  }
  componentDidMount() {
    this.getPosts();
  }
  submit = (featureObj) => {
    console.log("inside submit");
    let feature;
    if (feature == "latest") {
      console.log("submitting latest post");
      feature = { type: "post", content: "latest" };
      this.props.finish(feature);
    } else {
      console.log("submitting specific post: " + JSON.stringify(featureObj));
      feature = { type: "post", content: featureObj };
      this.props.submit(feature);
    }
  };
  render() {
    return (
      <div className="addFeatureEvent">
        {this.state.loading ? (
          <LoadingScreen />
        ) : (
          <div>
            Add Post:
            <br />
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                this.submit("latest");
              }}
            >
              Add Latest Post (Continual)
            </button>
            {this.state.selectedPost !== undefined ? (
              <button
                className="btn btn-sm btn-primary"
                onClick={() => {
                  this.submit(this.state.selectedPost);
                }}
              >
                Add Selected Post
              </button>
            ) : (
              ""
            )}
            <PostsTable
              posts={this.state.posts}
              selectPost={this.selectPost}
              selectedPost={this.state.selectedPost}
            />
          </div>
        )}
      </div>
    );
  }
}
