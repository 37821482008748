import React, { Component } from "react";

export default class GalleryManagementPanel extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    console.log("selectedImage url is : " + this.props.selectedImage.url);
    return (
      <div className="container galleryManagementPanel">
        <div className="row">
          {this.props.gallery.map((img, i) => (
            <div className="galleryManagementPanelImageContainer col col-sm-3 col-md-3 col-lg-2">
              <img
                onClick={() => {
                  this.props.selectImage(img);
                }}
                className={
                  this.props.selectedImage !== undefined &&
                  this.props.selectedImage.url == img.url
                    ? "galleryManagementImage selectedImage"
                    : "galleryManagementImage"
                }
                src={img.url}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}
