import React, { Component } from "react";
import { withFirebase } from "../../firebase";
import { compose } from "redux";
import FeaturePostView from "./FeaturePostView";

class FeaturePost extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      post: {},
    };
  }
  componentDidMount = () => {
    this.getPosts();
  };
  getPosts = () => {
    if (this.props.feature.content == "latest") {
      console.log("getting latest post");
      this.getLatestPost();
    } else {
      this.setFields(this.props.feature.content);
    }
  };
  getLatestPost = () => {
    this.props.firebase.db
      .collection("site")
      .doc("news")
      .collection("posts")
      .get()
      .then(
        function (res) {
          console.log("got posts");
          let posts = [];
          res.forEach((post, i) => {
            posts.push(post.data());
          });
          this.sortPosts(posts);
        }.bind(this)
      )
      .catch(function (err) {
        console.log("error getting posts: " + err.message);
      });
  };
  sortPosts = (posts) => {
    posts.sort((a, b) => {
      return parseFloat(b.time) - parseFloat(a.time);
    });
    this.setFields(posts[0]);
  };
  setFields = (post) => {
    if (post == undefined) {
      this.setState({ noPost: true, loading: false, post: {} });
    } else {
      this.setState({ loading: false, post: post, noPost: false });
    }
  };
  render() {
    return (
      <div className="featureContainer">
        {this.state.loading ? (
          <i className="spinner fas fa-circle-notch fa-spin" />
        ) : (
          <div className="featureContainer">
            {this.state.noPost ? (
              "No Posts"
            ) : (
              <FeaturePostView
                firebase={this.props.firebase}
                post={this.state.post}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(FeaturePost);
