import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <div className="footer">
        This is a beta version of{" "}
        <a href="https://www.brettkozmadesigns.com/" target="_blank">
          BK Design's
        </a>{" "}
        Bee Club App. Please Report Any Issues to brett@brettkozmadesigns.com
      </div>
    );
  }
}
