import React from "react";

export default function FeatureTable(props) {
  return (
    <div className="clientsTableContainer">
      <table className="table table-hover">
        <thead className="thead-dark">
          <tr>
            <th></th>
            <th className="sort" scope="col">
              Type
            </th>
            <th className="sort" scope="col">
              Title
            </th>
            <th className="sort" scope="col">
              Dynamic/Static
            </th>
          </tr>
        </thead>
        <tbody>
          {props.features.map((feature, i) => (
            <tr
              key={i}
              className={
                feature.content == "soonest" || feature.content == "latest"
                  ? "dynamic"
                  : ""
              }
            >
              <td>
                <input
                  type="checkbox"
                  checked={props.selectedFeature == feature ? true : false}
                  id="selectEvent"
                  onClick={() => {
                    props.selectFeature(feature);
                  }}
                ></input>
              </td>
              <th scope="row">{feature.type}</th>
              <th scope="row">{feature.title}</th>
              <th scope="row">
                {feature.content == "soonest" || feature.content == "latest"
                  ? "Dynamic Feature"
                  : "Specific Item"}
              </th>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
