import React from "react";
import { Link } from "react-router-dom";

export default function PagesTable(props) {
  return (
    <div>
      {props.loading ? (
        <i className="spinner fas fa-circle-notch fa-spin" />
      ) : (
        <div className="clientsTableContainer">
          <table className="table table-hover">
            <thead className="thead-dark">
              <tr>
                <th></th>
                <th className="sort" scope="col">
                  Page
                </th>
                <th className="sort" scope="col">
                  URL
                </th>
                <th className="sort" scope="col">
                  Enabled?
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    checked={props.selectedPage == "home" ? true : false}
                    id="selectClient"
                    onClick={() => {
                      props.selectPage("home");
                    }}
                  ></input>
                </td>
                <td scope="row">
                  <Link to="/admin-dashboard/pages/home">Home</Link>
                </td>
                <td>/</td>
                <td scope="row">Always</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    checked={props.selectedPage == "about" ? true : false}
                    id="selectClient"
                    onClick={() => {
                      props.selectPage("about");
                    }}
                  ></input>
                </td>
                <td scope="row">
                  <Link to="/admin-dashboard/pages/about">About</Link>
                </td>
                <td></td>
                <td scope="row">
                  {props.nav.pages.about.enabled ? "Yes" : "No"}
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    checked={props.selectedPage == "events" ? true : false}
                    id="selectClient"
                    onClick={() => {
                      props.selectPage("events");
                    }}
                  ></input>
                </td>
                <td scope="row">
                  <Link to="/admin-dashboard/pages/events">Events</Link>
                </td>
                <td></td>
                <td scope="row">
                  {props.nav.pages.events.enabled ? "Yes" : "No"}
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    checked={props.selectedPage == "news" ? true : false}
                    id="selectClient"
                    onClick={() => {
                      props.selectPage("news");
                    }}
                  ></input>
                </td>
                <td scope="row">
                  <Link to="/admin-dashboard/pages/news">News</Link>
                </td>
                <td></td>
                <td scope="row">
                  {props.nav.pages.news.enabled ? "Yes" : "No"}
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    checked={props.selectedPage == "members" ? true : false}
                    id="selectClient"
                    onClick={() => {
                      props.selectPage("members");
                    }}
                  ></input>
                </td>
                <td scope="row">
                  <Link to="/admin-dashboard/pages/members">Members</Link>
                </td>
                <td></td>
                <td scope="row">
                  {props.nav.pages.members.enabled ? "Yes" : "No"}
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    checked={props.selectedPage == "contact" ? true : false}
                    id="selectClient"
                    onClick={() => {
                      props.selectPage("contact");
                    }}
                  ></input>
                </td>
                <td scope="row">
                  <Link to="/admin-dashboard/pages/contact">Contact</Link>
                </td>
                <td></td>
                <td scope="row">
                  {props.nav.pages.contact.enabled ? "Yes" : "No"}
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    checked={props.selectedPage == "resources" ? true : false}
                    id="selectClient"
                    onClick={() => {
                      props.selectPage("resources");
                    }}
                  ></input>
                </td>
                <td scope="row">
                  <Link to="/admin-dashboard/pages/resources">Resources</Link>
                </td>
                <td></td>
                <td scope="row">
                  {props.nav.pages.resources.enabled ? "Yes" : "No"}
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    checked={props.selectedPage == "swarms" ? true : false}
                    id="selectClient"
                    onClick={() => {
                      props.selectPage("swarms");
                    }}
                  ></input>
                </td>
                <td scope="row">
                  <Link to="/admin-dashboard/pages/swarms">Swarms</Link>
                </td>
                <td></td>
                <td scope="row">
                  {props.nav.pages.swarms.enabled ? "Yes" : "No"}
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    checked={props.selectedPage == "marketplace" ? true : false}
                    id="selectClient"
                    onClick={() => {
                      props.selectPage("marketplace");
                    }}
                  ></input>
                </td>
                <td scope="row">
                  <Link to="/admin-dashboard/pages/marketplace">
                    Marketplace
                  </Link>
                </td>
                <td></td>
                <td scope="row">
                  {props.nav.pages.marketplace.enabled ? "Yes" : "No"}
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    checked={props.selectedPage == "gallery" ? true : false}
                    id="selectClient"
                    onClick={() => {
                      props.selectPage("gallery");
                    }}
                  ></input>
                </td>
                <td scope="row">
                  <Link to="/admin-dashboard/pages/gallery">Gallery</Link>
                </td>
                <td></td>
                <td scope="row">
                  {props.nav.pages.gallery.enabled ? "Yes" : "No"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
