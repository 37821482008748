import React, { Component } from "react";
import { Link } from "react-router-dom";
import SiteImageContainer from "./SiteImageContainer";

export default class AddImage extends Component {
  constructor() {
    super();
    this.state = {
      active: undefined,
      loading: true
    };
  }
  setSiteImages = () => {
    this.setState({ siteImages: this.props.siteImages, loading: false });
  };
  componentDidMount() {
    this.setSiteImages();
  }
  setActive = image => {
    let images = [...this.state.siteImages];
    let selectedImage;
    this.state.siteImages.forEach(
      function(siteImage, i) {
        if (image == siteImage) {
          console.log("found image that was clicked");
          if (images[i].selected) {
            images[i].selected = false;
            selectedImage = undefined;
          } else {
            images[i].selected = true;
            selectedImage = images[i];
          }
        } else {
          images[i].selected = false;
        }
      }.bind(this)
    );
    this.setState({ siteImages: images, active: selectedImage });
  };
  render() {
    return (
      <div className="card addImage">
        Add Image
        {this.state.loading ? (
          <i className="spinner fas fa-circle-notch fa-spin" />
        ) : (
          <div className="container">
            <div className="row">
              <SiteImageContainer
                siteImages={this.state.siteImages}
                select={this.setActive}
              />
            </div>
            {this.state.active !== undefined ? (
              <button
                className="btn btn-sm btn-secondary dashboardButton"
                onClick={() => {
                  this.props.selectImage(this.state.active);
                }}
              >
                Select Image
              </button>
            ) : (
              ""
            )}
            <hr />
            <div className="row">
              <div className="col">
                {" "}
                <p>
                  Don't see your picture? Upload it To Your Media Library In The
                  Images Component!
                </p>
                <br />
                <Link
                  className="btn btn-sm btn-secondary"
                  to="/admin-dashboard/images/upload"
                >
                  Upload Image
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
