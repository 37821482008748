import React, { Component } from "react";
import HomeManagement from "./Home/HomeManagement";
import EventsManagement from "./Events/EventsManagement";
import ResourcesManagement from "./Resources/ResourcesManagement";
import ContactManagement from "./Contact/ContactManagement";
import SwarmsManagement from "./Swarms/SwarmsManagement";
import NewsManagement from "./News/NewsManagement";
import MembersManagement from "./Members/MembersManagement";
import MarketplaceManagement from "./Marketplace/MarketplaceManagement";
import GalleryManagement from "./Gallery/GalleryManagement";
import AboutManagement from "./About/AboutManagement";

export default class PageManagement extends Component {
  constructor() {
    super();
    this.state = {
      activePage: undefined
    };
  }
  componentDidMount() {
    console.log("opening page: " + this.props.match.params.page);
  }
  render() {
    let activePage;
    switch (this.props.match.params.page) {
      case "home":
        activePage = <HomeManagement />;
        break;
      case "about":
        activePage = <AboutManagement />;
        break;
      case "events":
        activePage = <EventsManagement />;
        break;
      case "news":
        activePage = <NewsManagement />;
        break;
      case "members":
        activePage = <MembersManagement />;
        break;
      case "contact":
        activePage = <ContactManagement />;
        break;
      case "resources":
        activePage = <ResourcesManagement />;
        break;
      case "swarms":
        activePage = <SwarmsManagement />;
        break;
      case "marketplace":
        activePage = <MarketplaceManagement />;
        break;
      case "gallery":
        activePage = <GalleryManagement />;
        break;
      default:
        activePage = "Error Finding Page Undefined";
        break;
    }
    return <div>{activePage}</div>;
  }
}
