import React, { Component } from "react";
import formatDate from "../../../common/formatDate";

export default class PostsTable extends Component {
  sort = () => {
    this.props.sort();
  };
  render() {
    return (
      <div>
        <button className="btn btn-primary btn-sm" onClick={this.sort}>
          Sort Posts
        </button>
        <hr />
        {this.props.posts.map((post, i) => (
          <div className="postListItem">
            {post.title} - {formatDate(post.time)}{" "}
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                this.props.selectPost(post);
              }}
            >
              Open Post
            </button>
          </div>
        ))}
      </div>
    );
  }
}
