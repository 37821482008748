import React, { Component } from "react";
import RegisterForm from "./RegisterForm";
import { withFirebase } from "./../../firebase/index";
import { compose } from "redux";
import { admin } from "./../../versionInfo";
import NavBar from "../nav/NavBar";

class Login extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    console.log(
      "inside login componenet, auth is : " +
        JSON.stringify(this.props.firebase.auth)
    );
  }
  finished = status => {
    this.props.firebase.auth.onAuthStateChanged(
      function(user) {
        if (user) {
          console.log("user signed in, should forward");
          this.props.history.push("/dashboard/new-user");
        } else {
          console.log("user signed out");
        }
      }.bind(this)
    );
  };
  getUsers = () => {};
  render() {
    return (
      <div className="dashboardPanel">
        <NavBar
          nav={this.props.global.nav}
          colors={this.props.global.colors}
          loggedIn={this.props.signedIn}
          admin={this.props.admin}
        />
        <h1 className="display-5 headline text-center">Register Account</h1>
        <RegisterForm finished={this.finished} fire={this.props.firebase} />
      </div>
    );
  }
}

export default compose(withFirebase)(Login);
