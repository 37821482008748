import React from "react";
import { Link } from "react-router-dom";

export default function Tabs(props) {
  return (
    <div className="row">
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <Link
            className={
              props.active == "library" ? "nav-link active" : "nav-link"
            }
            to="/admin-dashboard/images"
          >
            Media Library
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              props.active == "upload" ? "nav-link active" : "nav-link"
            }
            to="/admin-dashboard/images/upload"
          >
            Upload
          </Link>
        </li>
      </ul>
    </div>
  );
}
