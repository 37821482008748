import React, { Component } from "react";
import { withFirebase } from "./../../firebase/index";
import { compose } from "redux";
import Gallery from "react-grid-gallery";

class FeatureGallery extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      gallery: [],
      haveGallery: false,
    };
  }
  setGalleryObject = () => {
    let images = [];
    this.state.galleryObj.images.forEach(function (image) {
      let newImg = {
        src: image.url,
        thumbnail: image.thumbFive,
        thumbnailWidth: 500,
        thumbnailHeight: 500,
        caption: image.caption,
      };
      images.push(newImg);
    });
    this.setState({ galleryImages: images, loading: false, haveGallery: true });
  };
  getGallery = () => {
    let images = [];
    this.props.firebase.db
      .collection("site")
      .doc("gallery")
      .get()
      .then(
        function (res) {
          let galleryObj = res.data();
          this.setState({ galleryObj: galleryObj }, () => {
            this.setGalleryObject();
          });
        }.bind(this)
      )
      .catch(function (err) {
        console.log("error getting gallery images: " + err.message);
      });
  };
  componentDidMount() {
    this.getGallery();
  }
  render() {
    return (
      <div className="featureContainer">
        {this.state.loading ? (
          <i className="spinner fas fa-circle-notch fa-spin" />
        ) : (
          <div className="featureContainer featureGalleryContainer">
            {this.state.haveGallery ? (
              <Gallery
                backdropClosesModal={true}
                images={this.state.galleryImages}
                rowHeight={180}
              />
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(FeatureGallery);
