import React from "react";
import GetParagraph from "./../../../../common/GetParagraph";

export default function HomeParagraph(props) {
  return (
    <div className="dashboardBlock container">
      <p className="display-5">About Paragraph</p>
      {props.message !== undefined ? (
        <p className="alert alert-primary">{props.message}</p>
      ) : (
        ""
      )}
      {props.loading ? (
        <i className="spinner fas fa-circle-notch fa-spin" />
      ) : (
        <div>
          <button onClick={props.save} className="btn btn-sm btn-secondary">
            Save
          </button>
          <GetParagraph onChange={props.onChange} value={props.paragraph} />
        </div>
      )}
    </div>
  );
}
