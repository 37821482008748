import React, { Component } from "react";
import { compose } from "redux";
import { withFirebase } from "./../../../../firebase/index";
import LoadingScreen from "./../../../../common/LoadingScreen";
import Alert from "./../../../../common/Alert";
import Confirmation from "./../../../../common/Confirmation";
import Backdrop from "../../../../common/Backdrop";
import PostsTable from "./PostsTable";
import AddPost from "./AddPost";
import EditPost from "./EditPost";
import "./news.css";

class NewsManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      message: undefined,
      messageType: "danger",
      addPost: false,
      editPost: false,
      deleteConfirmation: false,
      valid: true,
      posts: [],
    };
  }
  closeMessage = () => {
    this.setState({ message: undefined, messageType: "danger" });
  };
  getNews = () => {
    this.props.firebase.db
      .collection("site")
      .doc("news")
      .collection("posts")
      .get()
      .then(
        function (res) {
          let posts = [];
          res.forEach((post, i) => {
            console.log("got post");
            posts.push(post.data());
          });
          this.setState({ posts: posts, loading: false });
        }.bind(this)
      )
      .catch(
        function (err) {
          console.log("error getting news posts" + err.message);
          this.setState({
            message:
              "There Was An Error Getting The News Posts From The DB. Refresh And Try Again.",
            loading: false,
          });
        }.bind(this)
      );
  };
  addPost = () => {
    this.setState({ addPost: true });
  };
  cancelAddPost = () => {
    this.setState({
      addPost: false,
      message:
        "You Canceled The Add Post, But An Empty Post Was Created. Either Edit That Post Or Delete It To Keep A Clean News Feed.",
      messageType: "danger",
    });
  };
  finishAddPost = () => {
    this.setState(
      {
        message: "Successfully Saved News Post",
        messageType: "success",
        addPost: false,
      },
      () => {
        this.getNews();
      }
    );
  };
  componentDidMount = () => {
    this.getNews();
  };
  selectPost = (post) => {
    if (this.state.selectedPost == post) {
      this.setState({ selectedPost: undefined });
    } else {
      this.setState({ selectedPost: post });
    }
  };
  editPost = () => {
    this.setState({ editPost: true });
  };
  deletePost = () => {
    this.setState({
      confirmation: true,
      confirmationQuestion:
        "Are You Sure You Want To Delete This Post? This Cannot Be Undone.",
    });
  };
  startDeletePost = () => {
    this.props.firebase.db
      .collection("site")
      .doc("news")
      .collection("posts")
      .doc(this.state.selectedPost.id)
      .collection("images")
      .get()
      .then(
        function (res) {
          let photosToDelete = [];
          res.forEach((pic, i) => {
            photosToDelete.push(pic.data());
          });
          this.deletePhotos(photosToDelete);
        }.bind(this)
      )
      .catch(function (err) {
        console.log("error getting images from db: " + err.message);
      });
  };
  deletePhotoFromStorage = (pic) => {
    console.log("inside deletePhotoFromStorage");
    return new Promise(
      function (res, rej) {
        this.props.firebase.storage
          .ref(pic.path)
          .delete()
          .then(function (obj) {
            console.log("successfully deleted image");
            res("success");
          })
          .catch(function (err) {
            console.log("error deleting image: " + err.message);
            res(err.message);
          });
      }.bind(this)
    );
  };
  deletePhotoFromDB = (pic) => {
    this.props.firebase.db
      .collection("site")
      .doc("news")
      .collection("posts")
      .doc(this.state.selectedPost.id)
      .collection("images")
      .doc(pic.id)
      .delete()
      .then(function (res) {
        console.log("successfully deleted photo from db");
      })
      .catch(function (err) {
        console.log("error deleting photo from db");
      });
  };
  deletePhotos = (photosToDelete) => {
    console.log("inside deletePhotos");
    photosToDelete.forEach((pic, i) => {
      console.log("calling deletefromStoragee");
      this.deletePhotoFromStorage(pic).then(
        function (res) {
          this.deletePhotoFromDB(pic);
        }.bind(this)
      );
    });
    this.deletePostFromDB();
  };
  deletePostFromDB = () => {
    this.props.firebase.db
      .collection("site")
      .doc("news")
      .collection("posts")
      .doc(this.state.selectedPost.id)
      .delete()
      .then(
        function (res) {
          console.log("successfully deleted post");
          this.setState(
            { message: "Successfully Deleted Post.", messageType: "success" },
            () => {
              this.getNews();
            }
          );
        }.bind(this)
      )
      .catch(function (err) {
        console.log("error deleting post from db: " + err.message);
      });
  };
  answerConfirmDelete = (answer) => {
    if (answer) {
      console.log("will delete post");
      this.setState({ confirmation: false, confirmationQuestion: undefined });
      this.startDeletePost();
    } else {
      console.log("wont delete");
      this.setState({ confirmation: false, confirmationQuestion: undefined });
    }
  };
  finishEdit = () => {
    this.setState(
      {
        message: "Successfully Edited That Post.",
        messageType: "success",
        editPost: false,
      },
      () => {
        this.getNews();
      }
    );
  };
  render() {
    let message, confirmation;
    if (this.state.message !== undefined) {
      let messageType;
      if (
        this.state.messageType == undefined ||
        this.state.messageType == null
      ) {
        messageType = "danger";
      } else {
        messageType = this.state.messageType;
      }
      message = (
        <Alert
          closeMessage={this.closeMessage}
          alertType={messageType}
          message={this.state.message}
        />
      );
    } else {
      message = "";
    }
    if (this.state.confirmation) {
      confirmation = (
        <Confirmation
          question={this.state.confirmationQuestion}
          answer={this.answerConfirmDelete}
        />
      );
    } else {
      confirmation = "";
    }
    return (
      <div className="dashboardPanel">
        {this.state.loading ? (
          <LoadingScreen />
        ) : (
          <div>
            {this.state.editPost ? (
              <EditPost
                post={this.state.selectedPost}
                firebase={this.props.firebase}
                finish={this.finishEdit}
              />
            ) : (
              <div>
                <h1>News Management</h1>
                {message}
                <button
                  onClick={this.addPost}
                  className="btn btn-sm btn-secondary dashboardButton"
                >
                  Add Post
                </button>
                <br />
                <hr />
                <div className="dashboardControls">
                  {this.state.selectedPost !== undefined ? (
                    <div>
                      <button
                        className="btn btn-sm btn-secondary"
                        onClick={this.editPost}
                      >
                        Edit Post
                      </button>
                      <button
                        className="btn btn-sm btn-secondary"
                        onClick={this.deletePost}
                      >
                        Delete Post
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <PostsTable
                  posts={this.state.posts}
                  selectPost={this.selectPost}
                  selectedPost={this.state.selectedPost}
                />
              </div>
            )}
          </div>
        )}
        {this.state.addPost ? (
          <div style={{ width: "100%" }}>
            <AddPost
              firebase={this.props.firebase}
              finish={this.finishAddPost}
            />
            <Backdrop click={this.cancelAddPost} />
          </div>
        ) : (
          ""
        )}
        {confirmation}
      </div>
    );
  }
}

export default compose(withFirebase)(NewsManagement);
