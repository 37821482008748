import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Firebase, { FirebaseContext } from "./firebase/index";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "./typography.css";

const FirebaseInstance = new Firebase();
ReactDOM.render(
  <FirebaseContext.Provider value={FirebaseInstance}>
    <App style={{ height: "100%" }} />
  </FirebaseContext.Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
