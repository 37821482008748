import React, { Component } from "react";
import PagesTable from "./PagesTable";
import { compose } from "redux";
import { withFirebase } from "./../../../firebase/index";
import LoadingScreen from "../../../common/LoadingScreen";
import Alert from "./../../../common/Alert";

class Pages extends Component {
  constructor() {
    super();
    this.state = { loading: true, selectedPage: undefined };
  }
  selectPage = (page) => {
    console.log("selecting page: " + page);
    if (this.state.selectedPage == page) {
      this.setState({ selectedPage: undefined });
    } else {
      this.setState({ selectedPage: page });
    }
  };
  getNavStuff = () => {
    this.props.firebase.db
      .collection("site")
      .doc("nav")
      .get()
      .then(
        function (res) {
          if (res.exists){
            console.log('res exists');
            let nav = res.data();
             this.setState({ nav: nav, loading: false });
          } else {
            console.log('res doesnt exist');
            let emptyNav = {};
            emptyNav.pages = {
              home: { enabled: true },
              about: { enabled: true },
              events: { enabled: true },
              news: { enabled: true },
              members: { enabled: false },
              contact: { enabled: true },
              resources: { enabled: false },
              swarms: { enabled: false },
              marketplace: { enabled: false },
              gallery: { enabled: false },
              moreLink: { enabled: true },
            };
            this.setState({nav:emptyNav,loading:false})
          }
          
          
        }.bind(this)
      )
      .catch(function (err) {
        console.log("error: " + err.message);
      });
  };

  componentDidMount = () => {
    this.getNavStuff();
  };
  toggleVisibility = () => {
    this.setState({ loading: true });
    console.log("changing visibility");
    let nav = this.state.nav;
    if (nav.pages == undefined) {
      console.log("undefined pages, setting them to true");
      let pages = {
        home: { enabled: true },
        about: { enabled: true },
        events: { enabled: true },
        news: { enabled: true },
        members: { enabled: true },
        contact: { enabled: true },
        resources: { enabled: true },
        swarms: { enabled: true },
        marketplace: { enabled: true },
        gallery: { enabled: true },
        moreLink: { enabled: true },
      };
      nav.pages = pages;
    }
    let newPages;
    switch (this.state.selectedPage) {
      case "home":
        break;
      case "about":
        newPages = {
          home: { enabled: true },
          about: { enabled: !nav.pages.about.enabled },
          events: { enabled: nav.pages.events.enabled },
          news: { enabled: nav.pages.news.enabled },
          members: { enabled: nav.pages.members.enabled },
          contact: { enabled: nav.pages.contact.enabled },
          resources: { enabled: nav.pages.resources.enabled },
          swarms: { enabled: nav.pages.swarms.enabled },
          marketplace: { enabled: nav.pages.marketplace.enabled },
          gallery: { enabled: nav.pages.gallery.enabled },
          moreLink: { enabled: nav.pages.moreLink.enabled },
        };
        break;
      case "events":
        newPages = {
          home: { enabled: true },
          about: { enabled: nav.pages.about.enabled },
          events: { enabled: !nav.pages.events.enabled },
          news: { enabled: nav.pages.news.enabled },
          members: { enabled: nav.pages.members.enabled },
          contact: { enabled: nav.pages.contact.enabled },
          resources: { enabled: nav.pages.resources.enabled },
          swarms: { enabled: nav.pages.swarms.enabled },
          marketplace: { enabled: nav.pages.marketplace.enabled },
          gallery: { enabled: nav.pages.gallery.enabled },
          moreLink: { enabled: nav.pages.moreLink.enabled },
        };
        break;
      case "news":
        newPages = {
          home: { enabled: true },
          about: { enabled: nav.pages.about.enabled },
          events: { enabled: nav.pages.events.enabled },
          news: { enabled: !nav.pages.news.enabled },
          members: { enabled: nav.pages.members.enabled },
          contact: { enabled: nav.pages.contact.enabled },
          resources: { enabled: nav.pages.resources.enabled },
          swarms: { enabled: nav.pages.swarms.enabled },
          marketplace: { enabled: nav.pages.marketplace.enabled },
          gallery: { enabled: nav.pages.gallery.enabled },
          moreLink: { enabled: nav.pages.moreLink.enabled },
        };
        break;
      case "members":
        newPages = {
          home: { enabled: true },
          about: { enabled: nav.pages.about.enabled },
          events: { enabled: nav.pages.events.enabled },
          news: { enabled: nav.pages.news.enabled },
          members: { enabled: !nav.pages.members.enabled },
          contact: { enabled: nav.pages.contact.enabled },
          resources: { enabled: nav.pages.resources.enabled },
          swarms: { enabled: nav.pages.swarms.enabled },
          marketplace: { enabled: nav.pages.marketplace.enabled },
          gallery: { enabled: nav.pages.gallery.enabled },
          moreLink: { enabled: nav.pages.moreLink.enabled },
        };
        break;
      case "contact":
        newPages = {
          home: { enabled: true },
          about: { enabled: nav.pages.about.enabled },
          events: { enabled: nav.pages.events.enabled },
          news: { enabled: nav.pages.news.enabled },
          members: { enabled: nav.pages.members.enabled },
          contact: { enabled: !nav.pages.contact.enabled },
          resources: { enabled: nav.pages.resources.enabled },
          swarms: { enabled: nav.pages.swarms.enabled },
          marketplace: { enabled: nav.pages.marketplace.enabled },
          gallery: { enabled: nav.pages.gallery.enabled },
          moreLink: { enabled: nav.pages.moreLink.enabled },
        };
        break;
      case "resources":
        newPages = {
          home: { enabled: true },
          about: { enabled: nav.pages.about.enabled },
          events: { enabled: nav.pages.events.enabled },
          news: { enabled: nav.pages.news.enabled },
          members: { enabled: nav.pages.members.enabled },
          contact: { enabled: nav.pages.contact.enabled },
          resources: { enabled: !nav.pages.resources.enabled },
          swarms: { enabled: nav.pages.swarms.enabled },
          marketplace: { enabled: nav.pages.marketplace.enabled },
          gallery: { enabled: nav.pages.gallery.enabled },
          moreLink: { enabled: nav.pages.moreLink.enabled },
        };
        break;
      case "swarms":
        newPages = {
          home: { enabled: true },
          about: { enabled: nav.pages.about.enabled },
          events: { enabled: nav.pages.events.enabled },
          news: { enabled: nav.pages.news.enabled },
          members: { enabled: nav.pages.members.enabled },
          contact: { enabled: nav.pages.contact.enabled },
          resources: { enabled: nav.pages.resources.enabled },
          swarms: { enabled: !nav.pages.swarms.enabled },
          marketplace: { enabled: nav.pages.marketplace.enabled },
          gallery: { enabled: nav.pages.gallery.enabled },
          moreLink: { enabled: nav.pages.moreLink.enabled },
        };
        break;
      case "marketplace":
        newPages = {
          home: { enabled: true },
          about: { enabled: nav.pages.about.enabled },
          events: { enabled: nav.pages.events.enabled },
          news: { enabled: nav.pages.news.enabled },
          members: { enabled: nav.pages.members.enabled },
          contact: { enabled: nav.pages.contact.enabled },
          resources: { enabled: nav.pages.resources.enabled },
          swarms: { enabled: nav.pages.swarms.enabled },
          marketplace: { enabled: !nav.pages.marketplace.enabled },
          gallery: { enabled: nav.pages.gallery.enabled },
          moreLink: { enabled: nav.pages.moreLink.enabled },
        };
        break;
      case "gallery":
        newPages = {
          home: { enabled: true },
          about: { enabled: nav.pages.about.enabled },
          events: { enabled: nav.pages.events.enabled },
          news: { enabled: nav.pages.news.enabled },
          members: { enabled: nav.pages.members.enabled },
          contact: { enabled: nav.pages.contact.enabled },
          resources: { enabled: nav.pages.resources.enabled },
          swarms: { enabled: nav.pages.swarms.enabled },
          marketplace: { enabled: nav.pages.marketplace.enabled },
          gallery: { enabled: !nav.pages.gallery.enabled },
          moreLink: { enabled: nav.pages.moreLink.enabled },
        };
        break;
      default:
        break;
    }
    nav.pages = newPages;
    this.props.firebase.db
      .collection("site")
      .doc("nav")

      .set(nav)
      .then(
        function (res) {
          console.log("toggled visibility");
          this.setState({
            loading: false,
            message: "Successfully Toggled Visibility",
            messageType: "success",
            selectedPage: undefined,
          });
        }.bind(this)
      )
      .catch(
        function (err) {
          console.log("error toggling visibility: " + err.message);
          this.setState({
            message:
              "There Was An Error Toggling Visibility. Refresh And Try Again.",
            messageType: "danger",
          });
        }.bind(this)
      );
  };
  closeMessage = () => {
    this.setState({ message: undefined, messageType: "danger" });
  };
  render() {
    let message;
    if (this.state.message !== undefined) {
      let messageType;
      if (
        this.state.messageType == undefined ||
        this.state.messageType == null
      ) {
        messageType = "danger";
      } else {
        messageType = this.state.messageType;
      }
      message = (
        <Alert
          closeMessage={this.closeMessage}
          alertType={messageType}
          message={this.state.message}
        />
      );
    } else {
      message = "";
    }
    return (
      <div>
        {this.state.loading ? (
          <LoadingScreen />
        ) : (
          <div className="dashboardPanel">
            <h1 className="display-5 headline">Pages Management</h1>
            {message}
            {this.state.selectedPage !== undefined &&
            this.state.selectedPage !== "home" ? (
              <div>
                <button
                  className="btn btn-sm btn-secondary"
                  onClick={this.toggleVisibility}
                >
                  Enable/Disable
                </button>
              </div>
            ) : (
              ""
            )}
            <PagesTable
              loading={this.state.loading}
              selectedPage={this.state.selectedPage}
              selectPage={this.selectPage}
              nav={this.state.nav}
            />
          </div>
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(Pages);
