import React, { Component } from "react";
import { PhotoshopPicker } from "react-color";
import ColorPicker from "./ColorPicker";
import Backdrop from "./../../../common/Backdrop";

export default class Colors extends Component {
  constructor() {
    super();
    this.state = {
      setPrimaryColor: false
    };
  }
  setPrimaryColor = () => {
    this.setState({
      setPrimaryColor: true,
      oldPrimaryColor: this.props.primaryColor
    });
  };
  closePrimaryColor = () => {
    this.setState({
      setPrimaryColor: false,
      primaryColor: this.state.oldPrimaryColor
    });
    this.props.answerPrimaryColor(this.state.oldPrimaryColor);
  };
  answerPrimaryColor = (color, event) => {
    this.props.answerPrimaryColor(color.hex);
  };
  finalAnswerPrimaryColor = (color, event) => {
    this.setState({ setPrimaryColor: false });
  };
  setSecondaryColor = () => {
    this.setState({
      setSecondaryColor: true,
      oldSecondaryColor: this.props.secondaryColor
    });
  };
  closeSecondaryColor = () => {
    this.setState({
      setSecondaryColor: false,
      secondaryColor: this.state.oldSecondaryColor
    });
    this.props.answerSecondaryColor(this.state.oldSecondaryColor);
  };
  answerSecondaryColor = (color, event) => {
    this.props.answerSecondaryColor(color.hex);
  };
  finalAnswerSecondaryColor = (color, event) => {
    this.setState({ setSecondaryColor: false });
  };

  setButtonColor = () => {
    this.setState({
      setButtonColor: true,
      oldButtonColor: this.props.buttonColor
    });
  };
  closeButtonColor = () => {
    this.setState({
      setButtonColor: false,
      buttonColor: this.state.oldButtonColor
    });
    this.props.answerButtonColor(this.state.oldButtonColor);
  };
  answerButtonColor = (color, event) => {
    this.props.answerButtonColor(color.hex);
  };
  finalAnswerButtonColor = (color, event) => {
    this.setState({ setButtonColor: false });
  };

  setBackgroundColor = () => {
    this.setState({
      setBackgroundColor: true,
      oldBackgroundColor: this.props.backgroundColor
    });
  };
  closeBackgroundColor = () => {
    this.setState({
      setBackgroundColor: false,
      backgroundColor: this.state.oldBackgroundColor
    });
    this.props.answerBackgroundColor(this.state.oldBackgroundColor);
  };
  answerBackgroundColor = (color, event) => {
    this.props.answerBackgroundColor(color.hex);
  };
  finalAnswerBackgroundColor = (color, event) => {
    this.setState({ setBackgroundColor: false });
  };
  render() {
    return (
      <div>
        {" "}
        {this.props.loading ? (
          <i className="spinner fas fa-circle-notch fa-spin" />
        ) : (
          <div className="dashboardBlock">
            <h1 className="display-5">Colors</h1>
            {this.props.message !== undefined ? (
              <p className="alert alert-primary">{this.props.message}</p>
            ) : (
              ""
            )}
            <div className="subBlock">
              {" "}
              <p className="lead">Primary Color:</p>
              <div className="inline">
                <div
                  className="colorExample"
                  style={{
                    backgroundColor: this.props.primaryColor,
                    borderRadius: "50%"
                  }}
                />
                <button
                  className="btn btn-sm btn-secondary"
                  onClick={this.setPrimaryColor}
                >
                  Set Primary Color
                </button>
              </div>
            </div>
            <div className="subBlock">
              {" "}
              <p className="lead">Secondary Color:</p>
              <div className="inline">
                <div
                  className="colorExample"
                  style={{
                    backgroundColor: this.props.secondaryColor,
                    borderRadius: "50%"
                  }}
                />
                <button
                  className="btn btn-sm btn-secondary"
                  onClick={this.setSecondaryColor}
                >
                  Set Secondary Color
                </button>
              </div>
            </div>

            <div className="subBlock">
              {" "}
              <p className="lead">Background Color:</p>
              <div className="inline">
                <div
                  className="colorExample"
                  style={{
                    backgroundColor: this.props.backgroundColor,
                    borderRadius: "50%"
                  }}
                />
                <button
                  className="btn btn-sm btn-secondary"
                  onClick={this.setBackgroundColor}
                >
                  Set Background Color
                </button>
              </div>
            </div>

            <div className="subBlock">
              {" "}
              <p className="lead">Button Color:</p>
              <div className="inline">
                <div
                  className="colorExample"
                  style={{
                    backgroundColor: this.props.buttonColor,
                    borderRadius: "50%"
                  }}
                />
                <button
                  className="btn btn-sm btn-secondary"
                  onClick={this.setButtonColor}
                >
                  Set Button Color
                </button>
              </div>
            </div>

            {this.props.loading ? (
              <button className="btn btn-secondary btn-sm" disabled>
                <i className="spinner fas fa-circle-notch fa-spin" />
              </button>
            ) : (
              <div>
                <button
                  className="btn btn-secondary btn-sm"
                  onClick={this.props.saveSiteColors}
                >
                  Save Site Colors
                </button>{" "}
                <button
                  onClick={this.props.setDefaultColors}
                  className="btn btn-secondary btn-sm"
                >
                  Set Default Colors
                </button>
              </div>
            )}
          </div>
        )}
        {this.state.setPrimaryColor ? (
          <div>
            {" "}
            <ColorPicker
              color={this.props.primaryColor}
              source="Primary"
              answer={this.answerPrimaryColor}
              finalAnswer={this.finalAnswerPrimaryColor}
            />
            <Backdrop click={this.closePrimaryColor} />
          </div>
        ) : (
          ""
        )}
        {this.state.setSecondaryColor ? (
          <div>
            {" "}
            <ColorPicker
              color={this.props.secondaryColor}
              source="Secondary"
              answer={this.answerSecondaryColor}
              finalAnswer={this.finalAnswerSecondaryColor}
            />
            <Backdrop click={this.closeSecondaryColor} />
          </div>
        ) : (
          ""
        )}
        {this.state.setBackgroundColor ? (
          <div>
            {" "}
            <ColorPicker
              color={this.props.backgroundColor}
              source="Background"
              answer={this.answerBackgroundColor}
              finalAnswer={this.finalAnswerBackgroundColor}
            />
            <Backdrop click={this.closeBackgroundColor} />
          </div>
        ) : (
          ""
        )}
        {this.state.setButtonColor ? (
          <div>
            {" "}
            <ColorPicker
              color={this.props.buttonColor}
              source="Button"
              answer={this.answerButtonColor}
              finalAnswer={this.finalAnswerButtonColor}
            />
            <Backdrop click={this.closeButtonColor} />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
